<h3>
  Flexibler Zuschlag
  <button class="btn btn-link" *ngIf="('workflow.price.increase.flex'|HasPerm)" (click)="openUpdateModal()">
    <i class="fa fa-pencil"></i>
  </button>
  <button class="btn btn-link" *ngIf="('workflow.price.increase.flex'|HasPerm)" (click)="delete()">
    <i class="fa fa-minus red-font"></i>
  </button>
</h3>

<div class="whitebox ordersituation whitebox-margin">
  <div>Zuschlag: {{flexPriceIncrease.increase|money:2}}&nbsp;%</div>
  <div>Quelle:
    <ng-container [ngSwitch]="flexPriceIncrease.source">
      <ng-container *ngSwitchCase="1">Vorgang</ng-container>
      <ng-container *ngSwitchCase="2">Import</ng-container>
    </ng-container>
  </div>
</div>
