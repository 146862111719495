import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Lister} from '../../../common/wrapper.models';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {OfferRejectionReason, OfferRejectionReasonForm} from './config-offer-rejection-reason.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigOfferRejectionReasonAxiosService {
  constructor() {
  }

  static create(form: OfferRejectionReasonForm): Promise<OfferRejectionReason | null> {
    return AxiosHttpClient.post<OfferRejectionReason | null>(
      `${environment.apiv4uri}config/offer/rejection/reason`,
      form,
    );
  }

  static update(id: number, form: OfferRejectionReasonForm): Promise<OfferRejectionReason | null> {
    return AxiosHttpClient.put<OfferRejectionReason | null>(
      `${environment.apiv4uri}config/offer/rejection/reason/${id}`,
      form,
    );
  }

  static delete(id: number): Promise<any> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}config/offer/rejection/reason/${id}`
    );
  }

  static list(query?: {[key: string]: any }): Promise<Lister<OfferRejectionReason>> {
    return AxiosHttpClient.get<Lister<OfferRejectionReason>>(
      `${environment.apiv4uri}config/offer/rejection/reasons`,
      query,
    );
  }
}
