import {Component, ErrorHandler, Input, NgZone, OnDestroy, OnInit} from '@angular/core';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {OfferService} from '../../workflow-services/offer.service';
import {OfferListData} from '../../models/offer.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {Lister} from '../../../../common/wrapper.models';
import {noop} from '../../../../helper/noop';
import {DateHelper} from '../../../../helper/date-helper';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {OfferInfoModalService} from '../history/offer-info-modal.service';
import {MailModalService} from '../../../mail/mail-modal.service';
import {OfferRejectionReason} from '../../../../lazy/config/offer-rejection/config-offer-rejection-reason.model';
import {OfferAnalysisQueryParams} from '../analysis/offer-analysis.model';
import Helper from '../../../../helper/helper';
import {BroadcastChannel} from 'broadcast-channel';
import {OfferAnalysisParamHelper} from '../analysis/offer-analysis-param-helper';
import {OfferListService} from './offer-list.service';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'offer-list',
  templateUrl: './offer-list.component.html'
})
export class OfferListComponent extends SearchBaseComponent<OfferListService, OfferListData> implements OnInit, OnDestroy {
  @Input() listData: Lister<OfferListData>;
  @Input() rejectionReasons: Lister<OfferRejectionReason>;
  protected type = 'offer';

  private channelId: string;
  private channel: BroadcastChannel<OfferAnalysisQueryParams>;

  public filterFormErrors: { [key: string]: any } = {};
  protected handles400Errors = true;

  constructor(protected service: OfferListService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService,
              protected offerService: OfferService,
              private ngZone: NgZone,
              private alertService: AlertService,
              private infoModalService: OfferInfoModalService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.channelId = Helper.guid();

    this.channel = new BroadcastChannel<OfferAnalysisQueryParams>(
      `offer-analysis-inter-window-interactivity-${this.channelId}`
    );
    this.channel.addEventListener('message', params => {
      const q = this.query();
      const query: any = {...OfferAnalysisParamHelper.stringifyParams(params), page: 1, sort: q.sort, order: q.order};
      if (!OfferAnalysisParamHelper.match(q, query)) {
        this.ngZone.run(() => {
          this.serviceCall(query);
        });
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.channel.close().then(() => {});
  }

  apply(query: string, data): void {
    this.data = this.query(query);
    this.sendQueryParameters(this.analyzeParams);
    this.list = data;
  }

  history(id: number): void {
    this.infoModalService.open(id).subscribe();
  }

  setStatus(obj: OfferListData, status: number | null): void {
    this.offerService.status(obj.id, status).subscribe(response => {
      this.alertService.add('success', 'Angebotsstatus erfolgreich geändert!');
      obj.offer_status = response;
    }, () => {
      this.alertService.add('danger', 'Angebotsstatus konnte nicht geändert werden!');
    });
  }

  saveDate(event: any, obj: OfferListData): void {
    this.offerService.updateDate(obj.id, event).subscribe(() => {
      obj.resubmission_date = event;
      this.alertService.add('success', 'WV-Datum erfolgreich gespeichert!');
    }, () => {
      this.alertService.add('danger', 'WV-Datum konnte nicht gespeichert werden!');
    });
  }

  later(obj: Date | string | null | undefined): boolean {
    if (obj !== undefined) {
      return new Date() >= DateHelper.parse(obj);
    } else {
      return false;
    }
  }

  // -- mail handling

  mail(id: number, workflowId: number): void {
    this.defaultMail(id, workflowId);
  }

  defaultMailCallback(id: number | string): void {
    if (typeof id === 'number') {
      this.listData.objects.forEach(data => {
        if (data.id === id) {
          this.list.badges.open = this.list.badges.open + 1;
          data.offer_status = 1;
        }
      });
    }
  }

  get analyzeParams(): OfferAnalysisQueryParams {
    return OfferAnalysisParamHelper.parseParameters(this.query());
  }

  get analyzeParamsWithChannelId(): OfferAnalysisQueryParams & {channel_id: string} {
    return {...this.analyzeParams, channel_id: this.channelId};
  }

  protected sendQueryParameters(params: OfferAnalysisQueryParams): void {
    this.channel.postMessage(params).then(() => {});
  }

  get exportUri(): string {
    const params = EnvisiaLocation.httpParams(this.query()).toString();
    return `${environment.apiv4uri}offer/export${!params ? '' : '?' + params}`;
  }

  // -- list helper

  trackByFn(index: number, item: OfferListData): number {
    noop(this, index);
    return item.id;
  }

}
