import {Component, ErrorHandler, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {MaterialGroupListElement, MaterialValueType} from '../../../material/material-management.model';
import {LoadingWrapper} from '../../../../common/loading/loading-wrapper';
import {
  MaterialPlanningListAbstractComponent,
} from '../../../material/planning/material-planning-list.abstract-component';
import {
  MaterialPlanningDate,
  MaterialPlanningListQuery,
  MaterialSelect,
  MaterialSelectLister,
} from '../../../material/planning/material-planning.model';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {BusinessOrderMaterialPlanningListService} from './business-order-material-planning-list.service';
import {MaterialManagementService} from '../../../material/material-management.service';
import {UntypedFormBuilder} from '@angular/forms';
import {MaterialPlanningWeekListPaginator} from '../../../material/planning/material-planning-week-list-paginator';
import {MaterialArticleNamesListService} from '../../../material/articles/material-article-names-list.service';
import {debounceTime} from 'rxjs/operators';
import {Lister} from '../../../../common/wrapper.models';

@Component({
  selector: 'business-order-material-unlimited-list',
  templateUrl: './business-order-material-unlimited-list.component.html',
  styleUrls: ['../../../material/planning/material-planning.styles.scss']
})
export class BusinessOrderMaterialUnlimitedListComponent
  extends MaterialPlanningListAbstractComponent
  implements OnInit, OnChanges {
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() params: MaterialPlanningListQuery;
  @Input() suppliers: MaterialSupplier[];
  @Input() selectionData: MaterialSelect;
  @Input() fieldValues: { id: number, value: string }[];
  @Input() currentGroupId: number;
  @Input() currentSupplierId?: number;
  @Input() lockSelection: boolean;
  @Output() emitSelectMaterial = new EventEmitter<number>();
  @Output() emitSelectWeek = new EventEmitter<{ week: MaterialPlanningDate, variationId: number } | null>();

  fields: LoadingWrapper<MaterialValueType[]>;
  missingChoices: { [key: string]: string } = {};

  public filterFormErrors: { [key: string]: any } = {};

  constructor(protected service: BusinessOrderMaterialPlanningListService,
              protected errorHandler: ErrorHandler,
              protected mms: MaterialManagementService,
              protected manls: MaterialArticleNamesListService,
              protected fb: UntypedFormBuilder) {
    super(service, errorHandler, mms, manls, fb);
  }

  ngOnInit() {
    this.params = this.params ?? this.selectionData?.preselected_params as MaterialPlanningListQuery;
    this.params.group_id = this.params.group_id ?? this.currentGroupId;
    this.params.supplier_id = this.params.supplier_id ?? this.currentSupplierId;
    this.params.page = 1;
    super.ngOnInit();
    this.reloadDynamicFields(true, true).subscribe(data => {
      this.setDefaultFieldValues(data.fields);
      this.search();
    });
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => this.search());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentGroupId && !changes.currentGroupId.isFirstChange()) {
      this.selectGroup(this.currentGroupId);
    }
  }

  selectGroup(groupId: number): void {
    this.selectedWeek = null;
    this.selectedVariationId = null;
    this.form.patchValue({'group_id': groupId}, {emitEvent: false});

    this.reloadDynamicFields(true).subscribe(data => {
      this.setDefaultFieldValues(data.fields);
      this.search();
    });
  }

  protected postCall(data: MaterialSelectLister): void {
    if (!this.weekPaginator) {
      this.weekPaginator = new MaterialPlanningWeekListPaginator(data.weeks);
    } else {
      this.weekPaginator.reset(data.weeks);
    }
    this.weekPaginator.weekScrollPage(this.selectionData.weeks_to_bo_start);
  }

  selectMaterial(variation_id: number) {
    this.emitSelectMaterial.emit(variation_id);
  }

  setSelectedWeek(week: MaterialPlanningDate | null, variationId: number) {
    this.selectedWeek = week;
    this.selectedVariationId = variationId;
    this.emitSelectWeek.emit({week: week, variationId: variationId});
  }

  private setDefaultFieldValues(fields: MaterialValueType[]): void {
    this.missingChoices = {};
    const fieldValues = {
      'group_id': this.form.value.group_id,
      'preload_weeks': this.selectionData.weeks_to_bo_start + MaterialPlanningWeekListPaginator.pageElements,
    };
    this.fieldValues.forEach(fv => {
      fields.forEach(f => {
        if (f.id === fv.id && f.data_type === 'Auswahl' && !f.choices?.find(c => c === fv.value)) {
          this.missingChoices[`field_${fv.id}`] = fv.value;
        }
      });
      if (([2, 3, 41].find(id => id === fv.id)) && this.form.value.group_id === 1) {
        fieldValues[`field_${fv.id}`] = fv.value;
      } else if (([1, 4, 5, 41].find(id => id === fv.id)) && this.form.value.group_id === 2) {
        fieldValues[`field_${fv.id}`] = fv.value;
      }
    });
    this.form.patchValue(fieldValues, {emitEvent: false});
  }
}
