<div>
  <h3>
    Auftragsrahmen
    <button class="btn btn-link" *ngIf="('task.frame.change'|HasPerm)" (click)="openUpdateModal()">
      <i class="fa fa-pencil"></i>
    </button>
  </h3>

  <div class="whitebox ordersituation whitebox-margin" [ngClass]="{'alert alert-danger': !frame.approval}"
       *ngIf="frame">
    <div *ngIf="lastOffer">
      Aus Angebot:
      <ev-file-button [objId]="lastOffer.id" type="offer">
        {{ lastOffer.id }}
      </ev-file-button>
    </div>
    <div>Menge: {{ frame.quanity }}</div>
    <div>Kalk-Menge: {{ frame.calc_quanity }}</div>
    <div>Lose: {{ frame.lose }}</div>
    <div>Freigabe: {{ frame.release }} </div>
    <div *ngIf="frame.cost">Einkauf: {{ frame.cost}} $</div>
    <div>Einzel: {{ frame.single }} €</div>
    <div>Kalk: {{ frame.calc }}</div>
    <div *ngIf="cn">
      <i class="fa fa-warning" style="color: red;" *ngIf="!same_exchange"></i>
      <span [ngClass]="{red_alert: !same_exchange}">Wechselkurs: {{ frame.exchange }}</span>
    </div>
    <div *ngIf="cn && frame.producer">Produzent: {{frame.producer}}</div>
  </div>

  <ng-container *ngIf="frame?.date_frame_old">
    <h3>Alter Auftragsrahmen</h3>

    <div class="whitebox ordersituation whitebox-margin">
      <div>Stand: {{ frame.date_frame_old }}</div>
      <div>Menge: {{ frame.quanity_old }}</div>
      <div>Lose: {{ frame.lose_old }}</div>
      <div *ngIf="frame.cost">Einkauf: {{ frame.cost_old }}&nbsp;$</div>
      <div>Einzel: {{ frame.single_old }} €</div>
    </div>
  </ng-container>
</div>
