import {MaterialOrder} from './material-order.model';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MaterialOrderService {
  private uri = environment.apiv4uri + 'material/order/';

  constructor(protected http: HttpClient) {
  }

  get(id: number): Observable<MaterialOrder> {
    return this.http.get<MaterialOrder>(this.uri + id.toString());
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(this.uri + id.toString());
  }

  update(id: number, form: any): Observable<MaterialOrder> {
    return this.http.put<MaterialOrder>(this.uri + id.toString(), form);
  }

  updateState(id: number, status: number): Observable<MaterialOrder> {
    return this.http.put<MaterialOrder>(this.uri + `${id}/status`, status);
  }

  bewit(id: number): Observable<string> {
    const uri = `/api/v4/material/order/pdf/${id}`;
    return this.http.post<{ object: string }>(`${this.uri}pdf/${id}`, {uri: uri}).pipe(map(val => val.object));
  }
}
