import {Component, Input, OnInit} from '@angular/core';
import {
  MaterialPlanningDate,
  MaterialSelect,
} from '../../../material/planning/material-planning.model';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialPlanningService} from '../../../material/planning/material-planning.service';
import {MaterialGroupListElement} from '../../../material/material-management.model';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {ArticleBaseMaterialFormWithKey} from '../../../article/models/article-specification.model';
import {Lister} from '../../../../common/wrapper.models';

@Component({
  selector: 'business-order-material-select-modal',
  templateUrl: './business-order-material-select-modal.component.html',
  styleUrls: ['../../../material/planning/material-planning.styles.scss'],
})
export class BusinessOrderMaterialSelectModalComponent implements OnInit {
  @Input() selectionData: MaterialSelect;
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() suppliers: MaterialSupplier[];
  @Input() baseMaterial: ArticleBaseMaterialFormWithKey[];

  isMl: boolean;
  suggestedTgValue: string;
  currentGroupId?: number = null;

  selectedWeek: MaterialPlanningDate | null = null;
  selectedVariationId: number | null = null;

  usedFields: { id: number, value: any }[];

  lockSelection = false;

  hasCustomerSpecificationMaterial = false;

  constructor(private modalRef: NgbActiveModal,
              private service: MaterialPlanningService) {
  }

  ngOnInit(): void {
    const execution: string = this.selectionData.business_order.article_data.execution;
    this.hasCustomerSpecificationMaterial = (
      !!this.selectionData.article.data.customer_specification_material &&
      !!this.selectionData.article.data.customer_specification_material_type
    );
    this.isMl = execution && execution.startsWith('ML');
    const bmArticle =
      this.baseMaterial.find(bm => bm.key === this.selectionData.article.data.material_internal) ??
      this.baseMaterial.find(bm => bm.key === this.selectionData.article.data.manufacturer);
    if (bmArticle?.data?.tg === null || bmArticle?.data?.tg === undefined) {
      this.suggestedTgValue =   '- C°';
    } else if (bmArticle.data.tg < 150) {
      this.suggestedTgValue = '140 C°';
    } else if (bmArticle.data.tg < 170) {
      this.suggestedTgValue = '150 C°';
    } else {
      this.suggestedTgValue = '170 C°';
    }
    const baseMaterial = this.selectionData.planning.filter(x => x.component === 1);
    const rigidSelected = baseMaterial.find(x => (x.selected || x.conflict) && x.inventory?.group_id === 2);
    const mlSelected = baseMaterial.find(x => (x.selected || x.conflict) && x.inventory?.group_id === 1);
    this.currentGroupId = (!rigidSelected && this.isMl) || mlSelected ? 1 : 2;
    this.usedFields = this.selectionData.preselected_params['fields'];
  }

  selectMaterial(variationId: number): void {
    this.lockSelection = true;
    this.service.setMaterial(
      this.selectionData.business_order.id,
      this.selectionData.business_order.index,
      { variation_id: variationId },
    ).subscribe(response => {
      this.modalRef.close(response);
    });
  }

  setGroup(id: number): void {
    this.currentGroupId = id;
    this.usedFields = this.selectionData.fields;
    this.selectedWeek = null;
    this.selectedVariationId = null;
  }

  setSelectedWeek(data: {week: MaterialPlanningDate, variationId: number} | null) {
    this.selectedWeek = data.week;
    this.selectedVariationId = data.variationId;
  }
}
