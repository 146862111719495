import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderMaterialSelectModalComponent} from './business-order-material-select-modal.component';
import {forkJoin, from, Observable, throwError} from 'rxjs';
import {MaterialPlanningService} from '../../../material/planning/material-planning.service';
import {catchError, mergeMap} from 'rxjs/operators';
import {BusinessOrderMaterialResponse, MaterialSelect} from '../../../material/planning/material-planning.model';
import {MaterialSupplierService} from '../../../../lazy/material-supplier/material-supplier.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {ArticleSpecificationService} from '../../../article/services/article-specification.service';
import {ArticleBaseMaterialFormWithKey} from '../../../article/models/article-specification.model';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialGroupListBoMaterialService} from '../../../material/groups/material-group-list-bo-material.service';
import {MaterialGroup} from '../../../material/groups/material-group.model';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderMaterialSelectModalService {

  constructor(private modalService: NgbModal,
              private alertService: AlertService,
              private mss: MaterialSupplierService,
              private mgs: MaterialGroupListBoMaterialService,
              private ass: ArticleSpecificationService,
              private mps: MaterialPlanningService) {
  }

  open(id: number, index: number): Observable<BusinessOrderMaterialResponse> {
    return forkJoin([
      this.mps.getSelectableMaterial(id, index, {}),
      this.mgs.list(),
      this.mss.list({sort: 'priority', order: 'ASC'}),
      this.ass.list(),
    ])
      .pipe(
        catchError(error => {
          console.log('Modal Error', error);
          this.alertService.add('danger', 'Materialliste konnte nicht geladen werden');
          return throwError('modal error');
        }), mergeMap<{}[], Observable<BusinessOrderMaterialResponse>>(list => {
          const modalRef = this.modalService.open(
            BusinessOrderMaterialSelectModalComponent,
            {windowClass: 'modal2-full'}
          );
          modalRef.componentInstance.selectionData = list[0] as MaterialSelect;
          modalRef.componentInstance.groups = list[1] as Lister<MaterialGroup>;
          modalRef.componentInstance.suppliers = list[2] as MaterialSupplier[];
          modalRef.componentInstance.baseMaterial = list[3] as ArticleBaseMaterialFormWithKey[];
          return from(modalRef.result);
        }));
  }
}
