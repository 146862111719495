import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {MaterialOrderTransaction} from '../../material-order.model';
import {MaterialOrderIntakePrintService} from './material-order-intake-print.service';

@Component({
  selector: 'material-order-intake-print-modal',
  templateUrl: './material-order-intake-print-modal.component.html',
})
export class MaterialOrderIntakePrintModalComponent {
  @Input() transactions: MaterialOrderTransaction[];
  @Input() markedTransactionId?: number;

  constructor(private activeModal: NgbActiveModal,
              private service: MaterialOrderIntakePrintService) {
  }

  dismiss(): void {
    this.activeModal.dismiss();
  }

  print(): void {
    if (this.transactions.length > 0) {
      const t = this.transactions[0];
      this.service.bewit(t.order_id, t.index).subscribe(o => {
        window.open(o.object);
      });
    }
    this.activeModal.close();
  }
}
