<div>

  <h6>
    <ng-content></ng-content>
  </h6>

  <table class="table">

    <thead>
    <tr>
      <th>
        <ng-content select=".left"></ng-content>
      </th>
      <th>
        <ng-content select=".middle"></ng-content>
      </th>
      <th>
        <ng-content select=".right"></ng-content>
      </th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>


    <tr *ngFor="let obj of current;trackBy:trackByFn;let index = index">
      <td>
        <input type="text" style="width: 50px;"
               [ngClass]="{red_alert: checkError(index, 'quantity_till')}"
               [value]="cleanValue(obj.quantity_till)"
               [disabled]="disabled"
               (change)="onChange(index, 'quantity_till', $event.target.value)"
               (keyup)="onChange(index, 'quantity_till', $event.target.value)">
      </td>
      <td>
        <input type="text" style="width: 50px;"
               [ngClass]="{red_alert: checkError(index, 'percent')}"
               [value]="cleanValue(obj['percent'])"
               [disabled]="disabled"
               (change)="onChange(index, 'percent', $event.target.value)"
               (keyup)="onChange(index, 'percent', $event.target.value)">
      </td>
      <td>
        <input type="text" style="width: 50px;"
               [ngClass]="{red_alert: checkError(index, 'quantity')}"
               [value]="cleanValue(obj.quantity)"
               [disabled]="disabled"
               (change)="onChange(index, 'quantity', $event.target.value)"
               (keyup)="onChange(index, 'quantity', $event.target.value)">
      </td>

      <td>
        <div *ngIf="isLastRow(index) && !disabled">
          <button type="button" class="btn btn-link" (click)="add(index)"
                  style="padding: 0;margin-right: 5px">
            <i class="fa fa-plus"></i>
          </button>
          <button type="button" class="btn btn-link" (click)="remove(index)" style="padding: 0;" *ngIf="index !== 0">
            <i class="fa fa-minus"></i>
          </button>
        </div>
      </td>
    </tr>
    </tbody>

  </table>

</div>
