import {Component, ErrorHandler, Input} from '@angular/core';
import {SearchBaseComponent} from '../../../common/search/search.component';
import {KanbanCnService} from './kanban.cn.service';
import {KanbanListDataView} from './kanban.model';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {LocalStorage} from '../../../common/storage/local-storage';
import {Lister} from '../../../common/wrapper.models';
import {EMPTY, Observable} from 'rxjs';
import {deepCopy} from '../../../helper/deep-copy';

@Component({
  selector: 'kanban-list-cn',
  templateUrl: './kanban-list-cn.component.html'
})
export class KanbanListCnComponent extends SearchBaseComponent<KanbanCnService, KanbanListDataView> {
  @Input() listData: Lister<KanbanListDataView>;
  protected type = 'kanban';

  constructor(protected service: KanbanCnService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage) {
    super();
  }

  excelDownload(): () => Observable<string> {
    return () => {
      if (this.storageService) {
        const query = this.query();
        const empty = deepCopy(query);
        delete empty.page;
        const url = '/api/v4/workflow/kanban/cn/excel';
        let outUrl;
        if (Object.keys(empty).length === 0) {
          outUrl = url;
        } else {
          const data = EnvisiaLocation.httpParams(empty).toString();
          outUrl = `${url}?${data}`;
        }

        return this.service.bewit(outUrl);
      } else {
        return EMPTY;
      }
    };
  }

}
