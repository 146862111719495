import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {MaterialGroupListElement, MaterialValueType} from '../material-management.model';
import {MaterialSupplier} from '../../../lazy/material-supplier/material-supplier.model';
import {StateService} from '@uirouter/core';
import {
  MaterialInventoryWithPlanning,
  MaterialPlanningDate,
  MaterialPlanningListQuery,
  MaterialSelectLister
} from './material-planning.model';
import {MaterialPlanningListService} from './material-planning-list.service';
import {MaterialManagementService} from '../material-management.service';
import {LoadingWrapper} from '../../../common/loading/loading-wrapper';
import {Customer} from '../../customer/model/customer';
import {MaterialPlanningListAbstractComponent} from './material-planning-list.abstract-component';
import {MaterialPlanningWeekListPaginator} from './material-planning-week-list-paginator';
import {MaterialArticleNamesListService} from '../articles/material-article-names-list.service';
import {UntypedFormBuilder} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {EnvisiaObjects, Lister} from '../../../common/wrapper.models';
import {MaterialProductGroupUserRelation} from '../user-list/material-user-product-group-permission.models';

@Component({
  selector: 'material-planning-list',
  templateUrl: './material-planning-list.component.html',
  styleUrls: ['./material-planning.styles.scss'],
})
export class MaterialPlanningListComponent extends MaterialPlanningListAbstractComponent implements OnInit {
  @Input() productGroups: EnvisiaObjects<MaterialProductGroupUserRelation>;
  @Input() groups: Lister<MaterialGroupListElement>;
  @Input() params: MaterialPlanningListQuery;

  @Input() list: MaterialSelectLister;
  @Input() fields: LoadingWrapper<MaterialValueType[]>;

  @Input() suppliers: MaterialSupplier[];

  @Input() customer: Customer | null = null;

  protected type = 'material-planning-list';

  public filterFormErrors: { [key: string]: any } = {};

  constructor(protected service: MaterialPlanningListService,
              protected errorHandler: ErrorHandler,
              private stateService: StateService,
              protected mms: MaterialManagementService,
              protected manls: MaterialArticleNamesListService,
              protected fb: UntypedFormBuilder) {
    super(service, errorHandler, mms, manls, fb);
  }

  ngOnInit() {
    super.ngOnInit();
    this.reloadDynamicFields(true, true);
    this.formSubscription = this.form.valueChanges.pipe(debounceTime(200)).subscribe(() => this.search());
    this.weekPaginator = new MaterialPlanningWeekListPaginator(this.list.weeks);
  }

  selectCustomer(customer?: Customer): void {
    this.customer = customer ?? null;
    this.form.patchValue({'customer_id': customer?.id});
  }

  unsetDateLimit() {
    this.form.patchValue({'date_limit_from': null, 'date_limit_to': null});
  }

  setSelectedWeek(week: MaterialPlanningDate | null, variationId: number | null) {
    this.selectedWeek = week;
    this.selectedVariationId = variationId;
  }

  apply(query: MaterialPlanningListQuery, data: MaterialSelectLister) {
    super.apply(query, data);
    this.stateService.go(this.stateService.current.name, query, {reload: false, location: 'replace'});
  }

  goto(page: number): void {
    super.goto(page);
    this.setSelectedWeek(null, null);
  }

  get hasSelectedDate(): boolean {
    return !!this.form.value.date_limit_from || !!this.form.value.date_limit_to;
  }

  get numInventoryCols(): number {
    return (!!this.list.objects.find(o => o.has_selected_date) ? 2 : 1);
  }

  get numPlanningCols(): number {
    return (
      (!!this.list.objects.find(o => o.has_selected_date) ? 2 : 1) +
      (!!this.list.objects.find(o => o.has_selected_customer) ? 1 : 0)
    );
  }

  get selectedVariation(): MaterialInventoryWithPlanning {
    return this.selectedVariationId ? this.list.objects.find(v => v.inventory.id === this.selectedVariationId) : null;
  }
}
