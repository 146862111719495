<div class="whitelist-item-detail">
  <ng-container *ngIf="!!add.wastrel_reason?.reason">
    <p><strong>Ausschussgrund:</strong> {{add.wastrel_reason.reason}}</p>
  </ng-container>

  <ng-container [ngSwitch]="add.sub_type" *ngIf="!!add">
    <ng-container *ngSwitchCase="'reproduction_created_reproduction'">
      <comment-business-order-link [label]="'Verknüpfter Haupt BA:'"
                                   [boId]="add.linked_original_id"
                                   [boIndex]="add.linked_original_index">
      </comment-business-order-link>
    </ng-container>


    <ng-container *ngSwitchCase="'reproduction_created_original'">
      <comment-business-order-link [label]="'Verknüpfter Nachproduktions BA:'"
                                   [boId]="add.linked_reproduction_id"
                                   [boIndex]="add.linked_reproduction_index">
      </comment-business-order-link>
    </ng-container>


    <ng-container *ngSwitchCase="'reproduction_merged'">
      <comment-business-order-link [label]="'Haupt BA:'"
                                   [boId]="add.linked_original_id"
                                   [boIndex]="add.linked_original_index">
      </comment-business-order-link>
      <comment-business-order-link [label]="'Nachproduktions BA:'"
                                   [boId]="add.linked_reproduction_id"
                                   [boIndex]="add.linked_reproduction_index">
      </comment-business-order-link>

      <ng-container *ngIf="!!add.quantity && add.quantity > 0">
        <p><strong>Bestellmenge:</strong> {{add.quantity}}</p>
      </ng-container>
      <ng-container *ngIf="!!add.utilize_quantity && add.utilize_quantity > 0">
        <p><strong>Fe-Nutzen:</strong> {{add.utilize_quantity}}</p>
      </ng-container>
      <ng-container *ngIf="!!add.goodpiece && add.goodpiece > 0">
        <p><strong>Gutstück:</strong> {{add.goodpiece}}</p>
      </ng-container>
      <ng-container *ngIf="!!add.target_step">
        <p><strong>Nachfertigungs BA Schritt:</strong> {{add.source_step}}</p>
      </ng-container>
      <ng-container *ngIf="!!add.target_step">
        <p><strong>Ziel BA Schritt:</strong> {{add.target_step}}</p>
      </ng-container>
    </ng-container>


    <ng-container *ngSwitchCase="'reproduction_merge_point_selected'">
      <ng-container *ngIf="!!add.step_name_old">
        <p><strong>Alter Schritt:</strong> {{add.step_name_old}}</p>
      </ng-container>
      <ng-container *ngIf="!!add.step_name_new">
        <p><strong>Neuer Schritt:</strong> {{add.step_name_new}}</p>
      </ng-container>
    </ng-container>


    <ng-container *ngSwitchCase="'reproduction_merge_point_removed'">
      <ng-container *ngIf="!!add.step_name_old">
        <p><strong>Alter Schritt:</strong> {{add.step_name_old}}</p>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
