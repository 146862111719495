import {Injectable} from '@angular/core';
import {ListService} from '../../../common/search/search.service';
import {KanbanListDataView} from './kanban.model';
import {Observable} from 'rxjs';
import {Lister} from '../../../common/wrapper.models';
import {HttpClient} from '@angular/common/http';
import {EnvisiaLocation} from '../../../common/location/envisia-location';
import {environment} from '../../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class KanbanCnService implements ListService<KanbanListDataView> {

  constructor(private http: HttpClient) {
  }

  bewit(uri: string): Observable<string> {
    return this.http.post<{ object: string }>(
      environment.apiv4uri + 'workflow/kanban/cn/excel-bewit', {uri: uri}).pipe(
      map(val => val.object)
    );
  }

  list(query?: { [paramName: string]: any; }): Observable<Lister<KanbanListDataView>> {
    return this.http.get<Lister<KanbanListDataView>>(
      environment.apiv4uri + 'workflow/kanban/cn',
      {params: EnvisiaLocation.httpParams(query)}
    );
  }

}
