import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BusinessOrderStepService} from '../services/business-order-step.service';
import {
  BusinessOrderStepForm,
  BusinessOrderStepFormObject,
  BusinessOrderStepView,
} from '../models/business-order-step.models';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {noop} from '../../../../helper/noop';
import Helper from '../../../../helper/helper';
import {from} from 'rxjs';
import {BusinessOrderStepMinDurationModalComponent} from './business-order-step-min-duration-modal.component';
import {BusinessOrder} from '../models/business-order.models';
import {Lister} from '../../../../common/wrapper.models';
import {ReasonModel} from '../../../../lazy/config/justification-reasons/reason.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ArrayHelper} from '../../../../common/array-helper';
import {NumberHelper} from '../../../../helper/number-helper';

@Component({
  selector: 'business-order-step-stop',
  templateUrl: './business-order-step-stop.component.html',
})
export class BusinessOrderStepStopComponent implements OnInit {
  @Input() bo: BusinessOrder;
  @Input() step: BusinessOrderStepView;
  @Input() stepPrevious?: BusinessOrderStepView;
  @Input() manufacturer: string[];
  @Input() reasons: Lister<ReasonModel>;
  errors: { [key: string]: string[]; } = {};
  errorMessages: string[] = [];
  locked = false;
  form: FormGroup<BusinessOrderStepForm>;
  underDelivery = false;
  minDurationLock = false;
  lastGoodUtilize: number;
  lastGoodGoodPieces: number;

  constructor(private modalRef: NgbActiveModal,
              private modalService: NgbModal,
              private stepService: BusinessOrderStepService,
              private fb: FormBuilder,
              private alertService: AlertService) {
  }

  ngOnInit(): void {
    // Add default manufacturer if it is not contained in the list
    const isDef = !Helper.undefined(this.bo.target_material);
    if (isDef && !this.manufacturer.find(h => h === this.bo.target_material)) {
      this.manufacturer.push(this.bo.target_material);
    }

    this.lastGoodUtilize = this.stepPrevious?.good_utilize ?? this.bo.utilize_quantity;
    this.lastGoodGoodPieces = this.stepPrevious?.good_piece ?? this.bo.good_piece;
    const preForm = {
      comment: new FormControl<string>(null),
      good_utilize: new FormControl<number>(this.lastGoodUtilize, Validators.compose([Validators.required, Validators.min(0)])),
      wastrel_reason_id: new FormControl<number>(null),
      force: new FormControl<boolean>(false),
    };

    if (this.step.use_good_pieces) {
      preForm['good_pieces'] = new FormControl<number>(null, Validators.compose([
        Validators.required,
        Validators.min(0),
      ]));
    } else {
      preForm['wastrel'] = new FormControl(0, Validators.compose([
        Validators.required,
        Validators.min(0),
        Validators.max(this.stepPrevious?.good_piece),
      ]));
    }

    this.form = this.fb.group<BusinessOrderStepForm>(preForm);
  }

  submit(): void {
    if (this.locked) {
      return;
    }

    this.errors = this.formCheckValidationErrors();
    this.errorMessages = this.setErrorMessages();
    if (this.errorMessages.length > 0) {
      return;
    }

    this.locked = true;
    const data = this.form.value as BusinessOrderStepFormObject;
    data.force = (data.wastrel > 0) ? data.force === true : true;
    data.wastrel_reason_id = (data.wastrel <= 0) ? null : data.wastrel_reason_id;
    this.stepService.stepStop(this.step.id, this.step.index, this.step.ordering, data).subscribe(() => {
      this.alertService.add('success', 'Der Fertigungsschritt wurde erfolgreich abgemeldet!');
      this.locked = false;
      this.modalRef.close();
    }, (errorResponse: HttpErrorResponse) => {
      this.errors = errorResponse.error?.errors ?? {};
      this.errorMessages = this.setErrorMessages();
      this.underDelivery = !!this.errors['force']?.find(e => e === 'under_delivery');

      if (Helper.exists(errorResponse.error, 'obj.min_duration')) {
        this.minDurationLock = true;
      }

      this.locked = false;

      if (this.minDurationLock) {
        const modalRef = this.modalService.open(BusinessOrderStepMinDurationModalComponent, {windowClass: 'modal2-mm'});
        modalRef.componentInstance.remaining = errorResponse.error['obj.min_duration'][0].args[0];
        from(modalRef.result).subscribe(() => {
          this.modalRef.dismiss();
        }, () => {
          this.modalRef.dismiss();
        });
      }
    });
  }

  get selectedReason(): ReasonModel {
    return this.reasons.objects.find(r => r.id === this.form.value.wastrel_reason_id);
  }

  get commentIsRequired(): boolean {
    const reason = this.selectedReason;
    return this.form.value.wastrel > 0 && !!reason && reason.note_required;
  }

  formCheckValidationErrors(): { [key: string]: any } {
    const errors: { [key: string]: string[]; } = {};
    if (this.underDelivery && !this.form.value.force) {
      errors['force'] = ['under_delivery'];
    }

    if (this.step.use_good_pieces) {
      if (!NumberHelper.isDefined(this.form.value.good_pieces) || (this.form.value.good_pieces < 0)) {
        errors['good_pieces'] = ['illegal_good_pieces'];
      }
    } else {
      if (!NumberHelper.isDefined(this.form.value.wastrel)) {
        errors['wastrel'] = ['illegal_wastrel'];
      } else if ((this.form.value.wastrel > 0)) {
        if (this.lastGoodGoodPieces < this.form.value.wastrel) {
          errors['wastrel'] = ['wastrel_too_high'];
        }

        const reason = this.selectedReason;
        if (!reason) {
          errors['wastrel_reason_id'] = ['wastrel_reason_required'];
        } else if (reason?.note_required === true && !this.form.value.comment) {
          errors['comment'] = ['comment_needed'];
        }
      }
    }

    if (!NumberHelper.isDefined(this.form.value.good_utilize) || (this.form.value.good_utilize < 0)) {
      errors['good_utilize'] = ['illegal_good_utilize'];
    }

    return errors;
  }

  setErrorMessages(): string[] {
    return ArrayHelper.distinct(
      Object.keys(this.errors).reduce((accum, k) => [...accum, ...this.errors[k]], [] as string[])
    );
  }

  trackByFn(index: number, item: string): number {
    noop(this, item);
    return index;
  }

}
