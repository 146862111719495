import {SearchBaseComponent} from '../../../../common/search/search.component';
import {WorkflowOrderListDataElement} from '../order-request.models';
import {Component, ErrorHandler, Input} from '@angular/core';
import {Lister} from '../../../../common/wrapper.models';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {MailModalService} from '../../../mail/mail-modal.service';
import {OrderReceivedModalService} from '../modal/order-received-modal.service';
import {ProducerService} from '../../../producer/producer.service';
import {map} from 'rxjs/operators';
import {MailHeaderData} from '../../../mail/mail.models';
import {WorkflowOrderRequestService} from '../../workflow-services/workflow-order-request.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {StringHelper} from '../../../../common/string-helper';
import {Observable} from 'rxjs';
import {WorkflowCnOrderListService} from './workflow-cn-order-list.service';
import {DateRange} from '../../../../core/date-range/date-range.models';

@Component({
  selector: 'order-request-list',
  templateUrl: './workflow-cn-order-list.component.html'
})
export class WorkflowCnOrderListComponent extends SearchBaseComponent<WorkflowCnOrderListService, WorkflowOrderListDataElement> {
  @Input() listData: Lister<WorkflowOrderListDataElement>;

  constructor(protected locationService: EnvisiaLocation,
              protected errorHandler: ErrorHandler,
              protected service: WorkflowCnOrderListService,
              protected requestService: WorkflowOrderRequestService,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService,
              private alertService: AlertService,
              private producerService: ProducerService,
              private orderReceivedModalService: OrderReceivedModalService) {
    super();
  }

  protected type = 'order_request';

  mail(id: number, workflowId: number, producer?: string): void {
    const mailObs = this.producerService.detail(producer ? producer : '').pipe(
      map(val => new MailHeaderData(val.order_mail, 'Dear Sir or Madam,'))
    );

    this.mailModalService.open(
      id,
      'order',
      mailObs,
      null,
      'en',
      workflowId,
      false,
      'cn'
    ).subscribe(() => {
      this.list.objects.forEach((obj) => {
        if (obj.workflow_order_id === id) {
          obj.workflow_order_mail_sent = true;
        }
      });
    }, () => {
    });
  }

  open(data: WorkflowOrderListDataElement): void {
    this.orderReceivedModalService.fromIdAndIndex(data.workflow_order_id, data.workflow_order_index).subscribe(() => {
      this.search();
    });
  }

  cancel(obj: WorkflowOrderListDataElement): void {
    if (confirm('Möchten Sie diese Position wirklich stornieren?')) {
      this.requestService.cancelPosition(obj.workflow_order_id, obj.workflow_order_index).subscribe(val => {
        obj.workflow_order_status = val.state;
        this.alertService.add('success', 'Position erfolgreich storniert!');
        this.search();
      });
    }
  }

  remove(obj: WorkflowOrderListDataElement): void {
    if (confirm('Möchten Sie diese Position wirklich löschen?')) {
      this.requestService.removePosition(obj.workflow_order_id, obj.workflow_order_index).subscribe(val => {
        obj.workflow_order_status = val.state;
        this.alertService.add('success', 'Position erfolgreich gelöscht!');
        this.search();
      });
    }
  }

  excelDownload(): () => Observable<string> {
    return () => {
      const query = this.query();
      return this.requestService.bewit(query);
    };
  }

  updateDeliveryDate(dateRange: DateRange): void {
    const q = this.query();
    q.delivery_date_start = dateRange.start ?? null;
    q.delivery_date_end = dateRange.end ?? null;
    this.serviceCall(q);
  }

  updateDeliveryDateEstimated(dateRange: DateRange): void {
    const q = this.query();
    q.delivery_date_estimated_start = dateRange.start ?? null;
    q.delivery_date_estimated_end = dateRange.end ?? null;
    this.serviceCall(q);
  }
}
