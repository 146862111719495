import {Component, ErrorHandler, Input, OnInit} from '@angular/core';
import {MaterialSupplier} from '../../../../lazy/material-supplier/material-supplier.model';
import {Lister} from '../../../../common/wrapper.models';
import {MaterialOrderPosition} from '../material-order.model';
import {EnvisiaLocation} from '../../../../common/location/envisia-location';
import {LocalStorage} from '../../../../common/storage/local-storage';
import {MailModalService} from '../../../mail/mail-modal.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {SearchBaseComponent} from '../../../../common/search/search.component';
import {MaterialOrderPositionListService} from './material-order-position-list.service';
import {deepCopy} from '../../../../helper/deep-copy';
import Helper from '../../../../helper/helper';
import {Observable} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {StringHelper} from '../../../../common/string-helper';
import {MaterialGroup} from '../../groups/material-group.model';
import {MaterialProductGroupListElement} from '../../product-groups/material-product-groups.models';

@Component({
  selector: 'material-order-position-list',
  templateUrl: './material-order-position-list.component.html',
})
export class MaterialOrderPositionListComponent
  extends SearchBaseComponent<MaterialOrderPositionListService, MaterialOrderPosition>
  implements OnInit {
  @Input() suppliers: MaterialSupplier[];
  @Input() listData: Lister<MaterialOrderPosition>;
  @Input() productGroups: Lister<MaterialProductGroupListElement>;
  @Input() groups: Lister<MaterialGroup>;
  @Input() activeProductGroupId?: number;
  @Input() activeGroupIds: number[] = [];

  data: { [key: string]: any } = {};
  type = 'material_order_position';
  handles400Errors = true;

  constructor(protected service: MaterialOrderPositionListService,
              protected errorHandler: ErrorHandler,
              protected locationService: EnvisiaLocation,
              protected storageService: LocalStorage,
              protected mailModalService: MailModalService,
              protected alertService: AlertService) {
    super();
  }

  sanitizeLocation(query?: any) {
    this.data['overdue'] = this.data['overdue'] === 'true' ? true : null;
    super.sanitizeLocation(query);
  }

  filterError(error: HttpErrorResponse): void {
    this.filterFormErrors = error.error.errors ?? {};
  }

  toggleOverdue(): void {
    this.data['overdue'] = this.toggleOverdueState ? null : true;
    delete this.data['status'];
    const data = deepCopy(this.data);
    this.query({'status': null});
    const query = Helper.assign(this.query(), data);
    delete query.status;
    query.page = 1;

    this.serviceCall(query);
  }

  get toggleOverdueState(): boolean {
    return this.data['overdue'] === true || this.data['overdue'] === 'true';
  }

  excelDownload(): () => Observable<string> {
    return () => {
      const query = this.query();
      return this.service.excelBewit(query);
    };
  }

  setProductGroupId(productGroupId: number | null): void {
    const q = this.query();
    this.activeProductGroupId = productGroupId;
    q['product_group'] = productGroupId;
    this.serviceCall(q);
  }

  setGroupIds(groupIds: number[]): void {
    const q = this.query();
    this.activeGroupIds = groupIds;
    q['groups'] = StringHelper.concat(groupIds, ',');
    this.serviceCall(q);
  }
}
