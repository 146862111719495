import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class MaterialOrderIntakePrintService {
  constructor(protected http: HttpClient) {
  }

  bewit(id: number, index: number): Observable<{object: string}> {
    return this.http.get<{object: string}>(`${environment.apiv4uri}material/pdf/intake/bewit/${id}/${index}`);
  }
}
