<!-- Header -->
<main-version></main-version>

<!-- use https://ui-router.github.io/ng1/docs/latest/classes/transition.transitionservice.html onBefore/success-->
<div id="loading" class="alert headalert" *ngIf="loader.show">Inhalte werden geladen...</div>
<div id="saving" class="alert alert-info headalert" *ngIf="loader.save">Inhalte wurden gespeichert!</div>

<main-alert></main-alert>

<!-- Navbar -->
<aside id="left-panel" [ngClass]="{tiled: start}">
  <div id="logo-header">
    <div id="logo">
      <a uiSref="start">
        <img src="/ui/assets/images/envisiaERP.png">
      </a>
    </div>
  </div>

  <nav role="navigation">
    <div class="error-message" *ngIf="start && !hasAnyRights">
      <div class="envisiasite">
        <div class="errorsite">
          <div class="error-img"><i class="fa fa-ban text-danger"></i></div>
          <div class="error-status"><h2>Unauthorized</h2>
            <h1>Zugriff verweigert</h1></div>
          <div class="error-message"> Ihnen sind keine Benutzerberechtigungen für diese Anwendung zugeteilt. <br>
            Bitte wenden Sie sich an die Geschäftsleitung oder an den Administrator, falls Sie diese benötigen.
          </div>
          <div class="error-link">
            <button class="btn btn-success btn-lg" (click)="logout()">Abmelden</button>
          </div>
        </div>
      </div>
    </div>


    <ul *ngIf="hasAnyRights">
      <li *ngIf="('crm.list'|HasPerm) || ('sales.restricted'|HasPerm)">
        <a evSref="a.crm.list" [ngClass]="{active: state.includes('a.crm')}">
          <div class="icon">
            <i class="fa fa-users"></i>
          </div>
          <div class="icon-label">
            Kunden
          </div>
        </a>
      </li>

      <li *ngIf="('material.supplier_view'|HasPerm)">
        <a evSref="a.material_supplier" [ngClass]="{active: state.includes('a.material_supplier')}">
          <div class="icon">
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="icon-label">
            Lieferanten
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm) || ('sales.restricted'|HasPerm)">
        <a evSref="a.workflow.o.list" [ngClass]="{active: state.includes('a.workflow.o')}">
          <div class="icon">
            <i class="fa fa-paper-plane"></i>
          </div>
          <div class="icon-label">
            Angebote
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.workflow.c.list" [ngClass]="{active: state.includes('a.workflow.c')}">
          <div class="icon">
            <i class="fa fa-tasks"></i>
          </div>
          <div class="icon-label">
            Aufträge
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm)">
        <a evSref="a.workflow.fetch.list" [ngClass]="{active: state.is('a.workflow.fetch.list')}">
          <div class="icon">
            <i class="fa fa-list"></i>
          </div>
          <div class="icon-label">
            Abrufe
          </div>
        </a>
      </li>

      <li *ngIf="('task.fetch.list'|HasPerm)">
        <a evSref="a.workflow.fetch.list_cam"
           [evParams]="{order: 'DESC_NULLS_LAST', sort: 'task_workflow_fetch_delivery'}"
           [ngClass]="{active: state.is('a.workflow.fetch.list_cam')}">
          <div class="icon">
            <i class="fa fa-list"></i>
          </div>
          <div class="icon-label">
            Abrufe CAM
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm)">
        <a evSref="a.workflow.kanban.de" [ngClass]="{active: state.includes('a.workflow.kanban.de')}">
          <div class="icon">
            <i class="fa fa-microchip" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Kanban DE
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm)">
        <a evSref="a.workflow.kanban.cn" [ngClass]="{active: state.includes('a.workflow.kanban.cn')}">
          <div class="icon">
            <i class="fa fa-microchip" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Kanban CN
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.inventory.list" [ngClass]="{active: state.includes('a.inventory')}">
          <div class="icon">
            <i class="fa fa-database" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Lager
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.workflow.contract.list" [ngClass]="{active: state.includes('a.workflow.contract')}">
          <div class="icon">
            <i class="fa fa-handshake-o"></i>
          </div>
          <div class="icon-label">
            Auftr.-Best.
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.workflow.d.list" [evParams]="{status: 1}" [ngClass]="{active: state.includes('a.workflow.d')}">
          <div class="icon">
            <i class="fa fa-cube"></i>
          </div>
          <div class="icon-label">
            Lieferscheine
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.workflow.i.list" [ngClass]="{active: state.includes('a.workflow.i')}">
          <div class="icon">
            <i class="fa fa-tasks"></i>
          </div>
          <div class="icon-label">
            Rechnungen
          </div>
        </a>
      </li>

      <li *ngIf="('correction_restricted'|HasPerm) || ('correction_all'|HasPerm)">
        <a evSref="a.correction.ic.list" [ngClass]="{active: state.includes('a.correction.ic')}">
          <div class="icon">
            <i class="fa fa-fast-backward"></i>
          </div>
          <div class="icon-label">
            RG Korrektur
          </div>
        </a>
      </li>

      <li *ngIf="('opos'|HasPerm)">
        <a evSref="a.workflow.opos_list" [ngClass]="{active: state.includes('a.workflow.opos_list')}">
          <div class="icon">
            <i class="fa fa-money"></i>
          </div>
          <div class="icon-label">
            Offene Posten
          </div>
        </a>
      </li>

      <li *ngIf="('dunning'|HasPerm)">
        <a evSref="a.dunning.list" [ngClass]="{active: state.includes('a.dunning')}">
          <div class="icon">
            <i class="fa fa-warning"></i>
          </div>
          <div class="icon-label">
            Mahnungen
          </div>
        </a>
      </li>

      <li *ngIf="('article.detail'|HasPerm)">
        <a evSref="a.article.list" [ngClass]="{active: state.includes('a.article')}">
          <div class="icon">
            <i class="fa fa-qrcode"></i>
          </div>
          <div class="icon-label">
            Artikel
          </div>
        </a>
      </li>

      <li *ngIf="('price.detail'|HasPerm) || ('price.change'|HasPerm)">
        <a evSref="a.price.list" [ngClass]="{active: state.includes('a.price')}" (click)="resetPrice($event)">
          <div class="icon">
            <i class="fa fa-balance-scale"></i>
            <i class="fa fa-exclamation-triangle red_alert" aria-hidden="true"
               *ngIf="share.price && ('price.detail'|HasPerm)"></i>
          </div>
          <div class="icon-label">
            Preisprüf.
          </div>
        </a>
      </li>

      <li *ngIf="('activity.activity'|HasPerm) || ('sales.restricted'|HasPerm)">
        <a evSref="akquise.list" [ngClass]="{active: state.includes('akquise')}">
          <div class="icon">
            <i class="fa fa-calendar"></i>
          </div>
          <div class="icon-label">
            Aktivitäten
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)">
        <a evSref="a.workflow.request.list" [ngClass]="{active: state.includes('a.workflow.request')}">
          <div class="icon">
            <i class="fa fa-percent"></i>
          </div>
          <div class="icon-label">
            Anfragen
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm) || ('task.offer.view'|HasPerm)||('order.list'|HasPerm)">
        <a evSref="a.workflow.order.list"
           [evParams]="{status: 0, order: 'DESC', sort: 'workflow_order_id'}"
           [ngClass]="{active: state.includes('a.workflow.order')}">
          <div class="icon">
            <i class="fa fa-truck"></i>
          </div>
          <div class="icon-label">
            Bestellungen
          </div>
        </a>
      </li>

      <li *ngIf="('material.transactions'|HasPerm)">
        <a evSref="a.material.inventory.list-select"
           [ngClass]="{active: state.includes('a.material') &&
                              !state.includes('a.material.order') &&
                              !state.includes('a.material.planning')}">
          <div class="icon">
            <i class="fa fa-inbox"></i>
          </div>
          <div class="icon-label">
            Materiallager
          </div>
        </a>
      </li>

      <li *ngIf="('material.planning.list'|HasPerm)">
        <a evSref="a.material.planning.list.landingpage" [ngClass]="{active: state.includes('a.material.planning')}">
          <div class="icon">
            <i class="fa fa-shopping-cart"></i>
          </div>
          <div class="icon-label">
            Materialplanung
          </div>
        </a>
      </li>

      <li *ngIf="('material.order.view'|HasPerm)">
        <a evSref="a.material.order.list"
           [evParams]="{'status': 50}"
           [ngClass]="{active: state.includes('a.material.order') && !state.includes('a.material.order.position')}">
          <div class="icon">
            <i class="fa fa-truck"></i>
          </div>
          <div class="icon-label">
            Mat. Bestellungen
          </div>
        </a>
      </li>

      <li *ngIf="('material.order.view'|HasPerm)">
        <a evSref="a.material.order.position.list"
           [evParams]="{'status': 50}"
           [ngClass]="{active: state.includes('a.material.order.position')}">
          <div class="icon">
            <i class="fa fa-truck"></i>
          </div>
          <div class="icon-label">
            Mat. Bestell Pos.
          </div>
        </a>
      </li>

      <li *ngIf="('ba.list'|HasPerm)">
        <a evSref="a.business_order.list" [ngClass]="{active: state.includes('a.business_order')}">
          <div class="icon">
            <i class="fa fa-cogs"></i>
          </div>
          <div class="icon-label">
            Betriebsaufträge
          </div>
        </a>
      </li>

      <li *ngIf="('ba.adapter.list'|HasPerm)">
        <a evSref="a.adapter.list" [ngClass]="{active: state.includes('a.adapter')}">
          <div class="icon">
            <i class="fa fa-search"></i>
          </div>
          <div class="icon-label">
            Adapterliste
          </div>
        </a>
      </li>

      <li *ngIf="('label.create'|HasPerm)">
        <a evSref="a.label.create" [ngClass]="{active: state.includes('a.icon-label')}">
          <div class="icon">
            <i class="fa fa-print" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Etiketten
          </div>
        </a>
      </li>

      <li *ngIf="('article.multilayerplan.change'|HasPerm)">
        <a evSref="a.multilayerplan.create" [ngClass]="{active: state.includes('a.multilayerplan')}">
          <div class="icon">
            <i class="fa fa-signal fa-rotate-90" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Aufbauplan
          </div>
        </a>
      </li>

      <li *ngIf="('printer'|HasPerm)">
        <a evSref="a.print.list" [ngClass]="{active: state.includes('a.print')}" (click)="resetPrintState($event)">
          <div class="icon">
            <i class="fa fa-print" aria-hidden="true"></i>
            <i class="fa fa-exclamation-triangle red_alert" aria-hidden="true"
               *ngIf="share.print_failed && ('printer'|HasPerm)"></i>
          </div>
          <div class="icon-label">
            Druckjobs
          </div>
        </a>
      </li>

      <li *ngIf="('task.change'|HasPerm)">
        <a evSref="a.jobErrorList"
           [ngClass]="{active: state.includes('a.jobErrorList'), 'job-error': jobErrorsData.value > 0}">
          <div class="icon">
            <i class="fa fa-envelope" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Versand Status
          </div>
        </a>
      </li>

      <li *ngIf="('task.view'|HasPerm)">
        <a evSref="d.cn_order_list">
          <div class="icon">
            <i class="fa fa-industry" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Abrufe CN
          </div>
        </a>
      </li>

      <li *ngIf="start && ('analysis'|HasPerm)">
        <a uiSref="analysis.fetch.overview" [uiParams]="{start: year, end: year}">
          <div class="icon">
            <i class="fa fa-line-chart"></i>
          </div>
          <div class="icon-label">
            Auswertungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('analysis.executive'|HasPerm)">
        <a uiSref="analysis.executive.liquidity">
          <div class="icon">
            <i class="fa fa-line-chart green"></i>
          </div>
          <div class="icon-label">
            GF Auswertungen
          </div>
        </a>
      </li>

      <li
        *ngIf="start && ('business_order.analysis'|HasPerm)">
        <a uiSref="analysis_business_order.step" [uiParams]="{start: year, end: year}">
          <div class="icon">
            <i class="fa fa-line-chart"></i>
          </div>
          <div class="icon-label">
            BA Auswertungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('material.analysis'|HasPerm)">
        <a uiSref="analysis_material.inventory">
          <div class="icon">
            <i class="fa fa-line-chart"></i>
          </div>
          <div class="icon-label">
            Material Auswertungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('workload.timetable'|HasPerm)">
        <a uiSref="a.workload.timetable">
          <div class="icon">
            <i class="fa fa-line-chart"></i>
          </div>
          <div class="icon-label">
            Auslastungskalender
          </div>
        </a>
      </li>

      <li *ngIf="start && ('invoice.automation'|HasPerm)">
        <a uiSref="a.workflow.i.invoice">
          <div class="icon">
            <i class="fa fa-eur" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Rechnungslauf
          </div>
        </a>
      </li>

      <li *ngIf="start && ('dunning'|HasPerm)">
        <a uiSref="a.dunning.automation">
          <div class="icon">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Mahnungslauf
          </div>
        </a>
      </li>

      <li *ngIf="start && ('settings.calculation'|HasPerm)">
        <a uiSref="a.calculation.list">
          <div class="icon">
            <i class="fa fa-table"></i>
          </div>
          <div class="icon-label">
            Kalkulationstabellen
          </div>
        </a>
      </li>

      <article-config-redirect type="base" *ngIf="start"></article-config-redirect>

      <li *ngIf="start && ('article.extra'|HasPerm)">
        <a uiSref="a.holiday.manage">
          <div class="icon">
            <i class="fa fa-calendar"></i>
          </div>
          <div class="icon-label">
            Feiertage
          </div>
        </a>
      </li>

      <li *ngIf="start && ('material.settings'|HasPerm)">
        <a uiSref="a.material.group.list">
          <div class="icon">
            <i class="fa fa-inbox"></i>
          </div>
          <div class="icon-label">
            Materialverwaltung
          </div>
        </a>
      </li>

      <li *ngIf="start && ('user.change'|HasPerm)">
        <a uiSref="a.user.list">
          <div class="icon">
            <i class="fa fa-user"></i>
          </div>
          <div class="icon-label">
            Benutzerverwaltung
          </div>
        </a>
      </li>

      <li *ngIf="start && ('label.settings'|HasPerm)">
        <a uiSref="a.label.settings">
          <div class="icon">
            <i class="fa fa-print"></i>
          </div>
          <div class="icon-label">
            Etiketteneinstellungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('settings.settings'|HasPerm)">
        <a uiSref="a.config.main">
          <div class="icon">
            <span class="fa fa-stack subscript right">
              <i class="fa fa-cog fa-stack-1x dimgray"></i>
              <i class="fa fa-cog fa-stack-2x"></i>
            </span>
          </div>
          <div class="icon-label">
            System Einstellungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('settings.settings'|HasPerm)">
        <a uiSref="a.config.price_increase_flex">
          <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-cog fa-stack-1x dimgray"></i>
           <i class="fa fa-euro fa-stack-2x"></i>
         </span>
          </div>
          <div class="icon-label">
            Kosten Einstellungen
          </div>
        </a>
      </li>

      <li *ngIf="start && (
                 ('field_service.config'|HasPerm) ||
                 ('settings.customer.industrial_sectors'|HasPerm) ||
                 ('settings.customer.departments'|HasPerm) ||
                 ('price.increase.global'|HasPerm))">
        <a uiSref="a.config.customer">
          <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-cog fa-stack-1x dimgray"></i>
           <i class="fa fa-user fa-stack-2x"></i>
         </span>
          </div>
          <div class="icon-label">
            Kunden Einstellungen
          </div>
        </a>
      </li>

      <li *ngIf="start && ('settings.settings'|HasPerm)">
        <a uiSref="a.config.pdf_text">
          <div class="icon">
         <span class="fa fa-stack subscript right">
           <i class="fa fa-file-o fa-stack-1x dimgray"></i>
           <i class="fa fa-user fa-stack-2x"></i>
         </span>
          </div>
          <div class="icon-label">
            Kunden Dokumente
          </div>
        </a>
      </li>

      <li *ngIf="start && ('ba.list'|HasPerm)">
        <a uiSref="a.dashboard.main" [uiParams]="{dashboard: true}">
          <div class="icon">
            <i class="fa fa-tasks"></i>
          </div>
          <div class="icon-label">
            Dashboard
          </div>
        </a>
      </li>

      <li *ngIf="start && ('plotting.list'|HasPerm)">
        <a href="#!/dashboard/plotting">
          <div class="icon">
            <span class="fa fa-stack subscript right">
              <i class="fa fa-file-picture-o fa-stack-1x dimgray"></i>
              <i class="fa fa-tasks fa-stack-2x"></i>
            </span>
          </div>
          <div class="icon-label">
            Plotting Dashboard
          </div>
        </a>
      </li>

      <li *ngIf="start && (('export.customer.contact'|HasPerm) || ('business_order.analysis'|HasPerm))">
        <a uiSref="a.export">
          <div class="icon">
            <i class="fa fa-download"></i>
          </div>
          <div class="icon-label">
            Export
          </div>
        </a>
      </li>

      <li *ngIf="start && ('cluster.administration.info'|HasPerm)">
        <a uiSref="a.cluster.info">
          <div class="icon">
            <i class="fa fa-server"></i>
          </div>
          <div class="icon-label">
            Cluster Informationen
          </div>
        </a>
      </li>

      <li *ngIf="start">
        <a class="click" (click)="logout(true)">
          <div class="icon">
            <i class="fa fa-sign-out" aria-hidden="true"></i>
          </div>
          <div class="icon-label">
            Abmelden
          </div>
        </a>
      </li>
    </ul>
  </nav>
</aside>

<div id="main" *ngIf="!start">
  <div id="content">

    <div id="header">
      <div class="buttonbar">
        <!-- Segment: Maybe a top bar -->
        <ui-view name="navigation"></ui-view>
      </div>
      <main-dropdown></main-dropdown>
    </div>

    <div class="main-view">
      <ui-view name="main"></ui-view>
      <ui-view></ui-view>
    </div>

  </div>
</div>
