import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {
  MaterialDateMapping,
  MaterialPlanningDate,
  MaterialPlanningDateWithColorCodes,
  MaterialInventoryWithPlanning,
  MaterialSelect,
} from '../../../material/planning/material-planning.model';
import {MaterialValueType} from '../../../material/material-management.model';

@Component({
  selector: 'tr[business-order-material-unlimited-list-row]',
  templateUrl: './business-order-material-unlimited-list-row.component.html',
  styleUrls: ['../../../material/planning/material-planning.styles.scss'],
})
export class BusinessOrderMaterialUnlimitedListRowComponent implements OnInit, OnChanges {

  @Input('business-order-material-unlimited-list-row') material: MaterialInventoryWithPlanning;
  @Input('business-order-material-unlimited-list-row-selection-data') selectionData: MaterialSelect;
  @Input('business-order-material-unlimited-list-row-week-pages') weekPages: number[];
  @Input('business-order-material-unlimited-list-row-marked-week') markedWeek: MaterialPlanningDate | null;
  @Input('business-order-material-unlimited-list-row-marked-variation-id') markedVariationId: number | null;
  @Input('business-order-material-unlimited-list-row-weeks') weeks: MaterialDateMapping[];
  @Input('business-order-material-unlimited-list-row-fields') fields: MaterialValueType[] = [];
  @Input('business-order-material-unlimited-list-row-lock-selection') lockSelection: boolean;

  @Output('business-order-material-unlimited-list-row-select-material') emitSelectMaterial = new EventEmitter<number>();
  @Output('business-order-material-unlimited-list-row-mark-week') emitMarkWeek =
    new EventEmitter<MaterialPlanningDate | null>();

  selectedVariation: boolean;
  markedVariation: boolean;
  conflictVariation: boolean;

  rowClass: { 'light-green': boolean, 'red_alert': boolean };

  constructor() {
  }

  ngOnInit(): void {
    this.checkSelection();
    this.checkMarking();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.markedVariationId && !changes.markedVariationId.isFirstChange()) {
      this.checkMarking();
    }
    if (
      (changes.selected && !changes.selected.isFirstChange()) ||
      (changes.material && !changes.material.isFirstChange())
    ) {
      this.checkSelection();
    }
  }

  checkSelection(): void {
    const selected = this.selectionData.planning?.find(m => m.variation_id === this.material.inventory.id);
    this.selectedVariation = selected?.selected;
    this.conflictVariation = selected?.conflict;
    this.rowClass = {
      'light-green': this.selectedVariation && !this.conflictVariation,
      'red_alert': this.conflictVariation,
    };
  }

  checkMarking(): void {
    this.markedVariation = this.markedVariationId === this.material.inventory.id;
  }

  selectMaterial() {
    this.emitSelectMaterial.emit(this.material.inventory.id);
  }

  setMarkedWeek(week: MaterialPlanningDateWithColorCodes) {
    if (this.markedWeek?.first_day_of_week === week.first_day_of_week && this.markedVariation) {
      this.emitMarkWeek.emit(null);
    } else {
      this.emitMarkWeek.emit(week);
    }
  }

  get weeksWithColors(): MaterialPlanningDateWithColorCodes[] {
    return this.weekPages.map(w => {
      const c = this.material.workload_list[w] as MaterialPlanningDateWithColorCodes;
      c.class_left = {};
      c.class_right = {};
      const selectedWeekDate = this.selectionData.week_start?.first_day_of_week?.split('T')[0];
      if (this.selectedVariation && c.first_day_of_week?.split('T')[0] === selectedWeekDate) {
        c.class_left['frame-left'] = true;
        c.class_right['frame-right'] = true;
      }

      if (c.first_day_of_week === this.markedWeek?.first_day_of_week && this.markedVariation) {
        c.color1 = 'light-blue';
        c.color2 = 'dark-blue';
      } else if (c.required_to_date <= c.secure_confirmed_to_date + this.material.inventory.amount) {
        c.color1 = 'light-green';
        c.color2 = 'dark-green';
      } else {
        c.color1 = 'light-red';
        c.color2 = 'dark-red';
      }

      c.class_left[c.color1] = true;
      c.class_right[c.color2] = true;

      return c;
    });
  }
}
