<!-- Confirmed -->
<div class="order-modal-box" style="width:200px;">
  <div class="order-modal-box-head label-info">
    Bestätigt
    <button type="button" class="ev-button pull-right"
            *ngIf="!!this.data.id && resetStatusAvailable && (this.data.status === 1)" (click)="resetState()">
      <i class="fa fa-undo"></i>
    </button>
  </div>
  <div class="order-modal-box-content">
    <div class="form-group">
      <label for="inputApprovalDeliveryDate" class="col-sm-4 control-label">Datum</label>
      <div class="col-sm-8">
        <ev-datepicker class="form-control" [allDates]="true" name="confirmed_date" [(ngModel)]="data.confirmed.date"
                    [evClass]="{red_alert: errors['data['+data.index+'].confirmed.date']}"
                    id="inputApprovalDeliveryDate">
        </ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-12">
        <!--ng-change="$ctrl.save($ctrl.data.confirmed.confirmed, 'confirmed', 'confirmed', 'bool')"-->
        <input type="checkbox"
               name="confirmed_confirmed"
               [(ngModel)]="data.confirmed.confirmed"
               id="inputConfirmedConfirmed-{{ index }}">
        <label for="inputConfirmedConfirmed-{{ index }}">
          Liefer-Termin (ETD) bestätigt
        </label>
      </div>
    </div>

    <div class="form-group">
      <label for="inputConfirmedQuantity" class="col-sm-4 control-label">Menge</label>
      <div class="col-sm-8">
        <!--ng-change="$ctrl.save($ctrl.data.confirmed.quantity, 'confirmed', 'quantity', 'int')"-->
        <input type="number"
               name="confirmed_quantity"
               [(ngModel)]="data.confirmed.quantity"
               [ngClass]="{red_alert: errors['data['+data.index+'].confirmed.quantity']}"
               id="inputConfirmedQuantity" class="form-control">
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-4 control-label" [for]="'input_confirmed_delivery_method_' + index">
        Transport Art:
      </label>
      <div class="col-sm-8">
        <select class="form-control"
                name="delivery_method"
                [id]="'input_confirmed_delivery_method_' + index"
                [ngClass]="{red_alert: errors['data['+data.index+'].confirmed.delivery_method']}"
                [(ngModel)]="data.confirmed.delivery_method">
          <option [ngValue]="null" selected>-</option>
          <option value="DHL Express">DHL Express</option>
          <option value="Flugzeug">Flugzeug</option>
          <option value="Zug">Zug</option>
          <option value="Schiff">Schiff</option>
        </select>
      </div>
    </div>

  </div>
  <order-box-history type="confirmed" [history]="history"></order-box-history>
</div>

<!-- Conveyed -->
<div class="order-modal-box" style="width:350px;">
  <div class="order-modal-box-head label-warning">
    Versand China
    <button type="button" class="ev-button pull-right"
            *ngIf="!!this.data.id && resetStatusAvailable && (this.data.status === 2)" (click)="resetState()">
      <i class="fa fa-undo"></i>
    </button>
  </div>
  <div class="order-modal-box-content">
    <div class="form-group">
      <label for="inputApprovalConveyedDate" class="col-sm-4 control-label">Datum</label>
      <div class="col-sm-8">
        <ev-datepicker class="form-control" [allDates]="true" name="conveyed_date" [(ngModel)]="data.conveyed.date"
                    [evClass]="{red_alert: errors['data['+data.index+'].conveyed.date']}"
                    id="inputApprovalConveyedDate">
        </ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="inputDeliveryDateEstimated" class="col-sm-4 control-label">Vor.&nbsp;Lief.</label>
      <div class="col-sm-8">
        <ev-datepicker id="inputDeliveryDateEstimated"
                       class="form-control"
                       name="delivery_date_estimated"
                       [allDates]="true"
                       [(ngModel)]="data.conveyed.delivery_date_estimated"
                       [ngClass]="{red_alert: errors['data['+data.index+'].conveyed.delivery_date_estimated']}">
        </ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="inputConveyedQuantity" class="col-sm-4 control-label">Menge</label>
      <div class="col-sm-8">
        <!-- ng-change="$ctrl.save($ctrl.data.conveyed.quantity, 'conveyed', 'quantity', 'int')"-->
        <input type="number"
               name="confirmed_quantity"
               [(ngModel)]="data.conveyed.quantity"
               [ngClass]="{red_alert: errors['data['+data.index+'].conveyed.quantity']}"
               id="inputConveyedQuantity" class="form-control">
      </div>
    </div>

    <div class="form-group" *ngIf="data.confirmed?.delivery_method === 'Schiff'">
      <label for="inputShipName" class="col-sm-4 control-label">Schiffsname</label>
      <div class="col-sm-8">
        <input type="text"
               name="ship_name"
               [(ngModel)]="data.conveyed.ship_name"
               [ngClass]="{red_alert: errors['data['+data.index+'].conveyed.ship_name']}"
               id="inputShipName" class="form-control">
      </div>
    </div>

    <div class="form-group">
      <label for="inputBookingId" class="col-sm-4 control-label">Buchungsnr.</label>
      <div class="col-sm-8">
        <input type="text"
               name="booking_id"
               [(ngModel)]="data.conveyed.booking_id"
               [ngClass]="{red_alert: errors['data['+data.index+'].conveyed.booking_id']}"
               id="inputBookingId" class="form-control">
      </div>
    </div>

    <div class="form-group">
      <label for="inputSupplierRgNr" class="col-sm-4 control-label">RG Nr Lieferant</label>
      <div class="col-sm-8">
        <input type="text"
               name="supplier_rg_nr"
               [(ngModel)]="data.conveyed.supplier_rg_nr"
               [ngClass]="{red_alert: errors['data['+data.index+'].conveyed.supplier_rg_nr']}"
               id="inputSupplierRgNr" class="form-control">
      </div>
    </div>

  </div>

  <order-box-history type="conveyed" [history]="history"></order-box-history>
</div>

<!-- Received -->
<div class="order-modal-box" style="width:230px;">
  <div class="order-modal-box-head label-primary">
    Empfangen
    <button type="button" class="ev-button pull-right"
            *ngIf="!!this.data.id && resetStatusAvailable && (this.data.status === 3)" (click)="resetState()">
      <i class="fa fa-undo"></i>
    </button>
  </div>
  <div class="order-modal-box-content">
    <div class="form-group">
      <label for="inputArrivalDate" class="col-sm-4 control-label">Eingang</label>
      <div class="col-sm-8">
        <ev-datepicker class="form-control" [allDates]="true" name="received_date" [(ngModel)]="data.received.date"
                    [evClass]="{red_alert: errors['data['+data.index+'].received.date']}"
                    id="inputArrivalDate">
        </ev-datepicker>
      </div>
    </div>

    <div *ngFor="let obj of multibox; let index = index"
         [ngClass]="{red_alert: errors['data['+data.index+'].received.data']}">
      <h4 class="pull-left">Lieferung: {{ index + 1 }}</h4>
      <div class="pull-right" *ngIf="index === 0">

        <button type="button" class="ev-button" (click)="raise()"
                *ngIf="('order.positions.unlimited'| HasPerm) || this.multibox.length < 2">
          <i class="fa fa-plus-square green"></i>
        </button>

      </div>
      <div class="pull-right" *ngIf="index > 0">

        <button type="button" class="ev-button" (click)="remove(index)">
          <i class="fa fa-minus-square red-font"></i>
        </button>

      </div>

      <div class="clearfix">&nbsp;</div>

      <div class="form-group">
        <label for="inputArrivalQuantity" class="col-sm-4 control-label">Menge</label>
        <div class="col-sm-8">
          <input type="number"
                 [name]="'received_quantity' + index"
                 [(ngModel)]="data.received.data[index].quantity"
                 [ngClass]="{red_alert: errors['data['+data.index+'].received.data['+index+'].quantity']}"
                 id="inputArrivalQuantity" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label for="inputDateCode" class="col-sm-4 control-label">Datecode</label>
        <div class="col-sm-8">
          <input type="text"
                 [name]="'received_date_code' + index"
                 [(ngModel)]="data.received.data[index].date_code"
                 [ngClass]="{red_alert: errors['data['+data.index+'].received.data['+index+'].date_code']}"
                 id="inputDateCode" class="form-control">
        </div>
      </div>
      <div class="form-group">
        <label for="inputStorageBinName" class="col-sm-4 control-label">Lager</label>
        <div class="col-sm-8">

          <select class="form-control" id="inputStorageBinName"
                  [(ngModel)]="data.received.data[index].storage_bin_name"
                  [ngClass]="{red_alert: errors['data['+data.index+'].received.data['+index+'].storage_bin_name']}">
            <option [ngValue]="null">-- Lagerplatz wählen --</option>
            <option *ngFor="let s of storage" [value]="s.name">{{ s.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label for="inputStorageBinNumber" class="col-sm-4 control-label">Lagerplatz</label>
        <div class="col-sm-8">
          <input type="number"
                 [name]="'received_storage_bin_number' + index"
                 [(ngModel)]="data.received.data[index].storage_bin_number"
                 [ngClass]="{red_alert: errors['data['+data.index+'].received.data['+index+'].storage_bin_number']}"
                 id="inputStorageBinNumber" class="form-control">
        </div>
      </div>
    </div>

  </div>
  <order-box-history type="received" [history]="history"></order-box-history>
</div>

<!-- Reviewed -->
<div class="order-modal-box" style="width:200px;">
  <div class="order-modal-box-head label-purple">
    Geprüft
    <button type="button" class="ev-button pull-right"
            *ngIf="!!this.data.id && resetStatusAvailable && (this.data.status === 4)" (click)="resetState()">
      <i class="fa fa-undo"></i>
    </button>
  </div>
  <div class="order-modal-box-content">
    <div class="form-group">
      <label for="inputReviewedDate" class="col-sm-4 control-label">Prüfung</label>
      <div class="col-sm-8">
        <ev-datepicker class="form-control" [allDates]="true" name="reviewed_date" [(ngModel)]="data.reviewed.date"
                    [evClass]="{red_alert: errors['data['+data.index+'].reviewed.date']}"
                    id="inputReviewedDate">
        </ev-datepicker>
      </div>
    </div>

    <div class="form-group">
      <label for="inputReviewedQuantity" class="col-sm-4 control-label">Menge</label>
      <div class="col-sm-8">
        <!-- ng-change="$ctrl.save($ctrl.data.reviewed.quantity, 'reviewed', 'quantity', 'int')" -->
        <input type="number"
               name="reviewed_quantity"
               [(ngModel)]="data.reviewed.quantity"
               [ngClass]="{red_alert: errors['data['+data.index+'].reviewed.quantity']}"
               id="inputReviewedQuantity" class="form-control">
      </div>
    </div>

    <div class="form-group">
      <label for="inputReviewedInspectionPlanExtra" class="col-sm-4 control-label">Sonderprüf.</label>
      <div class="col-sm-8">
        <input name="reviewed_inspection_plan_extra"
               [(ngModel)]="data.ipe.inspection_plan_extra"
               [ngClass]="{red_alert: errors['data['+data.index+'].ipe.inspection_plan_extra']}"
               id="inputReviewedInspectionPlanExtra" class="form-control">
      </div>
    </div>

    <ev-file-button
      [url]="'/api/v1/order/inspection/plan/?order=' + orderId + '&index=' + orderIndex + '&array_index=' + index"
      [link]="false" [disabled]="disableInspection">
      Prüfplan
    </ev-file-button>
  </div>
  <order-box-history type="reviewed" [history]="history"></order-box-history>
</div>

<!-- Expensed-->
<div class="order-modal-box" style="width:200px;">
  <div class="order-modal-box-head label-success">
    Gebucht
  </div>
  <div class="order-modal-box-content">
    <div class="form-group">
      <label for="inputExpensedDate" class="col-sm-4 control-label">Gebucht</label>
      <div class="col-sm-8">
        <ev-datepicker *ngIf="!articleLocked" class="form-control" [allDates]="true" name="expensed_date" [(ngModel)]="data.expensed.date"
                    [evClass]="{red_alert: errors['data['+data.index+'].expensed.date']}"
                    id="inputExpensedDate">
        </ev-datepicker>
        <input *ngIf="articleLocked" [disabled]="true" class="form-control">
      </div>
    </div>

    <div *ngFor="let obj of multibox; trackBy: trackById; let index = index"
         [ngClass]="{red_alert: errors['data['+data.index+'].expensed.quantities']}">
      <h4 class="pull-left">Lieferung: {{ index + 1 }}</h4>
      <div class="pull-right" *ngIf="index === 0">

        <button type="button" class="ev-button" (click)="raise()"
                *ngIf="('order.positions.unlimited'| HasPerm) || this.multibox.length < 2">
          <i class="fa fa-plus-square green"></i>
        </button>

      </div>
      <div class="pull-right" *ngIf="index > 0">

        <button type="button" class="ev-button" (click)="remove(index)">
          <i class="fa fa-minus-square red-font"></i>
        </button>

      </div>

      <div class="clearfix">&nbsp;</div>

      <div class="pull-left">Datecode</div>
      <div class="pull-right bold">{{ dateCodeInfo(index) }}</div>

      <div class="clearfix">&nbsp;</div>
      <div class="clearfix">&nbsp;</div>

      <div class="form-group">
        <label for="inputExpensedQuantity" class="col-sm-4 control-label">Menge</label>
        <div class="col-sm-8">
          <input type="number"
                 [name]="'expensed_quantity' + index"
                 [(ngModel)]="data.expensed.quantities[index]"
                 [ngClass]="{red_alert: errors['data['+data.index+'].expensed.quantities['+index+']']}"
                 id="inputExpensedQuantity" class="form-control"
                 [disabled]="articleLocked">
        </div>
      </div>

    </div>

  </div>

  <order-box-history type="expensed" [history]="history"></order-box-history>
</div>
