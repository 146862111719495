import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Lister} from '../../../common/wrapper.models';
import {AxiosHttpClient} from '../../../envisia/auth/axios-http-client';
import {ReasonModel, ReasonFormModel} from '../justification-reasons/reason.model';
import {AxiosResponse} from 'axios';
import Axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class BusinessOrderWastrelReasonAxiosService {
  constructor() {
  }

  static create(form: ReasonFormModel): Promise<ReasonModel | null> {
    return AxiosHttpClient.post<ReasonModel | null>(
      `${environment.apiv4uri}config/business/order/wastrel/reason`,
      form,
    );
  }

  static update(id: number, form: ReasonFormModel): Promise<ReasonModel | null> {
    return AxiosHttpClient.put<ReasonModel | null>(
      `${environment.apiv4uri}config/business/order/wastrel/reason/${id}`,
      form,
    );
  }

  static delete(id: number): Promise<any> {
    return AxiosHttpClient.delete(
      `${environment.apiv4uri}config/business/order/wastrel/reason/${id}`
    );
  }

  static list(
    query?: {[key: string]: any },
    abortController?: AbortController,
  ): Promise<AxiosResponse<Lister<ReasonModel>>> {
    return Axios.create().get<Lister<ReasonModel>>(
      `${environment.apiv4uri}config/business/order/wastrel/reasons`,
      {params: query, signal: abortController?.signal},
    );
  }
}
