import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BusinessOrderListElem} from '../models/business-order.models';
import {
  BusinessOrderMaterialSelectModalService
} from '../material-select-modal/business-order-material-select-modal.service';
import {AlertService} from '../../../../common/alert-service/alert.service';
import {BusinessOrderStartService} from '../start/business-order-start.service';
import {BusinessOrderStornoService} from '../storno/business-order-storno.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'tr[business-order-list-element]',
  templateUrl: './business-order-list-element.component.html',
  styleUrls: ['./business-order-list.component.scss']
})
export class BusinessOrderListElementComponent {
  @Input('business-order-list-element') ba: BusinessOrderListElem;
  @Output('business-order-list-element-reload') reload = new EventEmitter<void>();

  constructor(private alertService: AlertService,
              private businessOrderStartService: BusinessOrderStartService,
              private stornoService: BusinessOrderStornoService,
              private materialSelectModalService: BusinessOrderMaterialSelectModalService) {
  }

  get materialStatus(): 'button' | 'selected' | 'booked' | 'china' | null {
    if (!!this.ba.sds_nr?.endsWith('-c')) {
      return 'china';
    } else if ((this.ba.status > 1) && (this.ba.applied_material !== null)) {
      return 'booked';
    } else if ((this.ba.status === 0) && (this.ba.reproduction === null)) {
      return 'button';
    } else if ((this.ba.status === 0) && (this.ba.reproduction !== null)) {
      return 'selected';
    } else if ((this.ba.status > 0) && (this.ba.material_planning_base > 0)) {
      return 'selected';
    }
    return null;
  }

  get materialButtonStatus(): {} {
    if (!!this.ba.material_customer_specification) {
      return {'btn-danger': true, 'red_alert': true};
    } else if (!!this.ba.material_planning_no_mapping) {
      return {'btn-warning': true};
    } else {
      return {'btn-default': true};
    }
  }

  openMaterialSelectModal(): void {
    this.materialSelectModalService.open(this.ba.id, this.ba.index).subscribe(() => {
      this.reload.emit();
    }, () => {
    });
  }

  storno(): void {
    this.stornoService.call(this.ba).pipe(
      filter(response => response.confirm)
    ).subscribe(() => this.reload.emit());
  }

  startOrder(): void {
    this.businessOrderStartService.start(this.ba).subscribe(() => {
      this.reload.emit();
    }, error => {
      console.log('BA Start Error:', error);
    });
  }
}
