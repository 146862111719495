import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {BusinessOrderCreateReproductionService} from './business-order-create-reproduction.service';
import {ProductionFetchMin, ProductionFetchView} from '../../../models/fetch.models';
import {Article, ArticleCore} from '../../../../article/models/article.models';
import {ExtendedFrame} from '../../../models/frame.models';
import {ShippingInstructionBase} from '../../../../article/models/article-extra.models';
import {BusinessOrder} from '../../models/business-order.models';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {dateBefore} from '../../services/business-order.service';
import {BusinessOrderProposalService} from '../../services/business-order-proposal.service';
import {AlertService} from '../../../../../common/alert-service/alert.service';
import {BusinessOrderGoldDateErrorService} from '../business-order-gold-date-error.service';
import {Money} from '../../../../../common/money/Money';
import {debounceTime} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {debug} from '../../../../../helper/debug.func';
import {ConfigReproductionService} from '../../../../../lazy/config/reproduction/config-reproduction.service';
import {LoadingWrapper} from '../../../../../common/loading/loading-wrapper';
import {BusinessOrderReproductionReason} from '../../../../../lazy/config/reproduction/config-reproduction.model';
import {HolidayService} from '../../../../holiday/holiday.service';
import {ArticleWorkdayCalculation} from '../../../../article/workday-calculation/article-workday-calculation.model';
import {BusinessOrderCreateDirective} from '../business-order-create.directive';

export function hasRequiredNote(g: UntypedFormGroup) {
  const noteRequired = g.get('reason').value?.required;
  const note = g.get('note').value;
  return noteRequired && (note === null || note === undefined || note === '') ? {'note.required': true} : null;
}

@Component({
  selector: 'business-order-create-reproduction',
  templateUrl: './business-order-create-reproduction.component.html',
})
export class BusinessOrderCreateReproductionComponent
  extends BusinessOrderCreateDirective
  implements OnInit, OnChanges, OnDestroy {
  @Input() selected: BusinessOrder;
  @Input() date: ArticleWorkdayCalculation;
  @Input() validHash: boolean;
  @Input() fetches: ProductionFetchView[];
  @Input() fetchesMin: ProductionFetchMin;
  @Input() article: Article | ArticleCore;
  @Input() inventory: number;
  @Input() frame?: ExtendedFrame;
  @Input() workflowId: number;
  @Input() hasBusinessOrders: boolean;
  @Input() shippingInstructions: ShippingInstructionBase | undefined;
  @Output() updateQuantity: EventEmitter<number> = new EventEmitter<number>();
  @Output() update: EventEmitter<BusinessOrder> = new EventEmitter<BusinessOrder>();
  @Output() reloadInventory: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeForm: EventEmitter<void> = new EventEmitter<void>();
  businessOrderForm: UntypedFormGroup;
  hasErrors = false;
  errors: { [key: string]: any } = {};
  locked = false;
  private quantitySubscription: Subscription;

  amountPerUtilize: number;
  isMl = false;

  @Input() plistEmpty: boolean;

  reproductionReasonList: LoadingWrapper<BusinessOrderReproductionReason[]>;

  constructor(private service: BusinessOrderCreateReproductionService,
              private reproductionService: ConfigReproductionService,
              private fb: UntypedFormBuilder,
              private ngbModal: NgbModal,
              private proposalService: BusinessOrderProposalService,
              private alertService: AlertService,
              private holidayService: HolidayService,
              private goldDateService: BusinessOrderGoldDateErrorService) {
    super();
  }

  ngOnInit(): void {
    this.reproductionReasonList = new LoadingWrapper<BusinessOrderReproductionReason[]>(
      this.reproductionService.list(false)
    );
    this.initForm();
    const util = this.article.data.utilize_sum ? Money.parse(this.article.data.utilize_sum.toString()) : 0;
    this.amountPerUtilize = Number.isInteger(Number(util)) ? Number(util) : 0;

    // Check article for multilayer
    const execution = this.article.data.execution;
    this.isMl = execution && execution.indexOf('ML') !== -1;

    this.initSubscription();
    this.initMultilayerPlanCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reproductionSelected && !changes.reproductionSelected.isFirstChange()) {
      this.businessOrderForm.patchValue({
        'start_date': this.date.today?.toLocaleDateString(),
        'end_date': this.date.end_date?.toLocaleDateString(),
      });
    }

    this.initFetches(changes.fetchesMin);
  }

  ngOnDestroy(): void {
    this.destroySubscription();
  }

  initForm() {
    this.businessOrderForm = this.fb.group({
      'start_date': [this.date.today?.toLocaleDateString(), Validators.required],
      'end_date': [this.date.end_date?.toLocaleDateString(), Validators.required],
      'gold_date': [null],
      'utilize_quantity': [null, Validators.compose([Validators.required, Validators.min(1)])],
      'amount': [{value: 1, disabled: true}, Validators.required],
      'first_contract': [false, Validators.required],
      'express': [false, Validators.required],
      'ordered_quantity': [null, Validators.compose([Validators.min(1), Validators.required])],
      'reason': [null, Validators.required],
      'note': [null],
    }, {validator: Validators.compose([hasRequiredNote, dateBefore])});
  }

  destroySubscription() {
    this.quantitySubscription.unsubscribe();
  }

  initSubscription() {
    const orderedQuantityControl = (this.businessOrderForm.get('ordered_quantity') as UntypedFormControl);
    this.quantitySubscription = orderedQuantityControl.valueChanges
      .pipe(debounceTime(550))
      .subscribe(value => {
        this.updateQuantity.emit(value > 0 ? value : null);
      });
  }

  initFetches(fetches: { currentValue: ProductionFetchMin } | undefined | null): void {
    if (fetches && fetches.currentValue) {
      const totals = fetches.currentValue;

      if (totals.at >= 0 && totals.gold_date !== null && this.article.data['surface_area'] === 'Chem NI / AU') {
        this.businessOrderForm.patchValue({
          'gold_date': totals.gold_date,
        });

        // open a modal upfront that the gold_date
        // could not be handled, since we are out of time
        if (totals.warning) {
          this.goldDateService.open();
        }
      }
    }
  }

  submit() {
    if (this.locked) {
      return;
    }

    this.hasErrors = false;
    this.errors = {};
    this.locked = true;
    const data = {
      utilize: this.businessOrderForm.value.utilize_quantity,
      utilize_quantity: this.businessOrderForm.value.utilize_quantity,
      ordered_quantity: this.businessOrderForm.value.ordered_quantity,
      start_date: this.businessOrderForm.value.start_date,
      end_date: this.businessOrderForm.value.end_date,
      gold_date: this.businessOrderForm.value.gold_date,
      first_contract: this.businessOrderForm.value.first_contract,
      express: this.businessOrderForm.value.express,
      reason_id: this.businessOrderForm.value.reason.id,
      note: this.businessOrderForm.value.note,
      original_id: this.selected.id,
      original_index: this.selected.index,
      fetch_ids: (this.fetches ?? []).map(f => f.fetch.id),
      oa_nr: this.article.oa_nr,
      workflow_id: this.workflowId,
    };

    this.service.create(data).subscribe(success => {
      this.update.next(success);
      this.alertService.add('success', 'Betriebsauftrag für Nachfertigung erfolgreich erstellt');
      this.reloadInventory.emit();
      this.closeForm.emit();
      this.locked = false;
    }, (response: HttpErrorResponse) => {
      debug('Response:', response.error);
      this.locked = false;
      if (response.error.errors !== null && response.error.errors !== undefined) {
        this.hasErrors = true;
        this.errors = response.error.errors;
        if ('gold_date' in response.error.errors) {
          this.goldDateService.open();
        }
      }
      this.alertService.add('danger', 'Beim Erstellen des Betriebsauftrages ist ein Fehler aufgetreten!');
    });
  }
}
