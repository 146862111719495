<div class="canban">
  <h3>Kanban</h3>

  <form name="additional-form" class="form-inline" [formGroup]="canbanForm">
    <div class="whitebox">
      <div class="row">
        <div class="col-md-6">
          <span class="bold">Gelieferte Menge:</span> {{ delivered.actual }}
        </div>
        <div class="col-md-6">
          <span class="bold">Offene Menge:</span> {{ delivered.rest }}
        </div>
      </div>
    </div>
    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label>Produktionsfreigabe:</label></div>
        <div class="col-md-6 approval_wrapper" (click)="showApprovalModal()">
          <span [ngClass]="approval_class">{{data.approval_money}}</span>
          <span class="approval-addon">Stück</span>
        </div>
      </div>
    </div>

    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label for="minimumStock">Mindest Lagerbestand:</label></div>
        <div class="col-md-6">
          <div class="input-group">
            <input id="minimumStock" type="text" class="form-control" formControlName="minimum_stock"
                   [ngClass]="{'red_alert': errors['obj.minimum_stock']}">
            <span class="input-group-addon">Stück</span>
          </div>
        </div>
      </div>
    </div>

    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label for="newProductionWithin">Neuproduktion innerhalb:</label>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <input id="newProductionWithin" type="text" class="form-control" formControlName="new_production_within"
                   [ngClass]="{'red_alert': errors['obj.new_production_within']}">
            <span class="input-group-addon">Tage</span>
          </div>
        </div>
      </div>
    </div>

    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label for="fetchQuantityDelivered">Abruf Liefermenge (Bin):</label></div>
        <div class="col-md-6">
          <div class="input-group">
            <input id="fetchQuantityDelivered" type="text" class="form-control"
                   formControlName="fetch_quantity_delivered"
                   [ngClass]="{'red_alert': errors['obj.fetch_quantity_delivered']}">
            <span class="input-group-addon">Stück</span>
          </div>
        </div>
      </div>
    </div>

    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label>Aktuell in Produktion:</label></div>
        <div class="col-md-6">
          <div class="input-group">
            <strong>{{currentInProduction}}</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="whitebox">
      <div class="row">
        <div class="col-md-6"><label for="newProductionWithin">Kunden Abrufzyklus:</label>
        </div>
        <div class="col-md-6">
          <div class="input-group">
            <input id="customerFetchCycle" type="text" class="form-control" formControlName="customer_fetch_cycle"
                   [ngClass]="{'red_alert': errors['obj.customer_fetch_cycle']}">
            <span class="input-group-addon">Wochen</span>
          </div>
        </div>
      </div>
    </div>

    <div class="whitebox red_alert" *ngIf="minimumDelivery()">
      <div class="row">
        <div class="col-md-12">
          <i class="fa fa-warning"></i> {{ minimumDelivery() }}
        </div>
      </div>
    </div>

    <div class="whitebox" *ngIf="!minimumDelivery()">
      <div class="row">
        <div class="col-md-12">
          &nbsp;
        </div>
      </div>
    </div>

  </form>

</div>
