<td class="align-right">
  <a uiSref="a.material.order.list" [uiParams]="{id: position.order_id}">{{position.order_id}}</a>
</td>
<td>
  <material-order-position-status [id]="position.id"
                                  [status]="position.status"
                                  [edit]="false"
                                  [deleted]="!!position.date_deleted || !!position.order.date_deleted">
  </material-order-position-status>
</td>
<td class="align-right">{{position.index}}</td>
<td class="align-right">
  <span *ngIf="position.delivery_index === 0">1</span>
  <strong *ngIf="position.delivery_index > 0">{{position.delivery_index + 1}}</strong>
</td>
<td>{{position.variation.inventory.supplier_name}}</td>
<td>{{position.variation.vendor_article_id}}</td>
<td><div [ngbTooltip]="position.variation.variation_id">{{position.variation.article.name}}</div></td>
<td>{{position.order.delivery_id}}</td>
<td>{{position.order.date_created|date:'dd.MM.yyyy'}}</td>
<td class="align-right">{{position.amount|money}}</td>
<td>
  <ng-container *ngIf="position.date_delivery">{{position.date_delivery | date:'dd.MM.yyyy'}}</ng-container>
  <ng-container *ngIf="!position.date_delivery">-</ng-container>
</td>
<td>{{position.note}}</td>
<td class="icon">
  <button type="button"
          class="btn btn-link"
          title="Bestellübersicht öffnen"
          (click)="open()"
          *ngIf="!position.order.date_deleted">
    <i class="fa fa-bars"></i>
  </button>
</td>
<td class="icon">
  <ev-file-button [hawkUrl]="openPdf(position.order_id)"
                  [link]="true"
                  [hawk]="true"
                  *ngIf="!position.order.date_deleted">
    <i class="fa fa-file-pdf-o" aria-hidden="true" title="Bestellungs PDF öffnen"></i>
  </ev-file-button>
</td>
<td class="icon">
  <a target="_blank" title="Nach Bestellung im envisia finder suchen" [href]="finderUri">
    <i class="fa fa-search"></i>
  </a>
</td>
<td class="align-right" style="width:130px;">
  <button type="button"
          class="btn btn-default btn-sm"
          uiSref="a.material.order.intake"
          [uiParams]="{order_id: position.order_id, marked_position_id: position.id}"
          *ngIf="('material.order.transactions'|HasPerm) && !position.date_deleted && !position.order.date_deleted">
    Position einbuchen
  </button>
</td>
