<div>

  <h6>
    <ng-content></ng-content>
  </h6>

  <table class="table">

    <thead>
    <tr>
      <th>
        <ng-content select=".left"></ng-content>
      </th>
      <th>
        <ng-content select=".right"></ng-content>
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>

    <tbody>

    <tr *ngFor="let obj of current;trackBy:trackByFn;let index = index">
      <td>
        <input type="number" style="width: 200px;" step="1" min="1"
               [ngClass]="{red_alert: checkError(index, 'utilized')}"
               [value]="obj.utilized"
               [disabled]="disabled"
               (change)="onChange(index, 'utilized', $event.target.value)"
               (keyup)="onChange(index, 'utilized', $event.target.value)">
      </td>
      <td>
        <input type="number" style="width: 60px;" step="1" min="1"
               [ngClass]="{red_alert: checkError(index, 'days_left')}"
               [value]="obj.days_left"
               [disabled]="disabled"
               (change)="onChange(index, 'days_left', $event.target.value)"
               (keyup)="onChange(index, 'days_left', $event.target.value)">
      </td>

      <td class="icon">
        <button type="button" class="btn btn-link" (click)="add(index)"
                style="padding: 0; margin: -5px;" *ngIf="isLastRow(index) && !disabled">
          <i class="fa fa-plus"></i>
        </button>
      </td>
      <td class="icon">
        <button type="button" class="btn btn-link" (click)="remove(index)"
                style="padding: 0; margin: -5px;" *ngIf="isLastRow(index) && (index !== 0 || !firstRowIsClean()) && !disabled">
          <i class="fa fa-minus"></i>
        </button>
      </td>
    </tr>
    </tbody>

  </table>

</div>
