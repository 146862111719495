<div class="filter-row">
  <div class="filter-left">

    <button class="btn btn-default btn-sm" type="button" (click)="badge('0')" [ngClass]="{active: q('0')}">
      Bestellt
    </button>

    <button class="btn btn-info btn-sm" type="button" (click)="badge('1')" [ngClass]="{active: q('1')}">
      Bestätigt
    </button>

    <button class="btn btn-warning btn-sm" type="button" (click)="badge('2')" [ngClass]="{active: q('2')}">
      Versand China
    </button>

    <button class="btn btn-primary btn-sm" type="button" (click)="badge('3')" [ngClass]="{active: q('3')}">
      Empfangen
    </button>

    <button class="btn btn-purple btn-sm" type="button" (click)="badge('4')" [ngClass]="{active: q('4')}">
      Geprüft
    </button>

    <button class="btn btn-success btn-sm" type="button" (click)="badge('5')" [ngClass]="{active: q('5')}">
      Gebucht
    </button>

    <button class="btn btn-danger btn-sm" type="button"
            (click)="badge('true', 'cfa', true)"
            [ngClass]="{active: q('true', 'cfa')}">
      Handlungsbedarf
    </button>

    <button class="btn btn-default btn-sm" type="button" (click)="badge('all')"
            *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
            [ngClass]="{active: q('all')}">
      Alle
    </button>

    <button class="btn btn-danger btn-sm" type="button" (click)="badge('101')" [ngClass]="{active: q('101')}">
      Gelöscht
    </button>

    <ev-file-button [hawk]="true" [hawkUrl]="excelDownload()" [link]="false">
      Export
    </ev-file-button>

    <div style="padding-left: 20px; display: inline-block;">
      <button class="btn btn-link btn-default default-gray"
              type="button"
              title="DHL Express"
              [ngClass]="{'active': q2.delivery_method === 'DHL Express'}"
              (click)="badgeToggle('delivery_method', 'DHL Express')">
        <i class="fa fa-truck"></i>
      </button>
      <button class="btn btn-link btn-default default-gray"
              type="button"
              title="Flugzeug"
              [ngClass]="{'active': q2.delivery_method === 'Flugzeug'}"
              (click)="badgeToggle('delivery_method', 'Flugzeug')">
        <i class="fa fa-plane"></i>
      </button>
      <button class="btn btn-link btn-default default-gray"
              type="button"
              title="Zug"
              [ngClass]="{'active': q2.delivery_method === 'Zug'}"
              (click)="badgeToggle('delivery_method', 'Zug')">
        <i class="fa fa-train"></i>
      </button>
      <button class="btn btn-link btn-default default-gray"
              type="button"
              title="Schiff"
              [ngClass]="{'active': q2.delivery_method === 'Schiff'}"
              (click)="badgeToggle('delivery_method', 'Schiff')">
        <i class="fa fa-ship"></i>
      </button>
    </div>
    <div class="float-right">
      <div class="row">
        <div class="col-lg-6">
          <label class="control-label" title="Lief.Ter.(ETD)">Liefertermin (ETD):</label>
          <date-range [start]="data['delivery_date_start']"
                      [end]="data['delivery_date_end']"
                      (update)="updateDeliveryDate($event)">
          </date-range>
        </div>

        <div class="col-lg-6">
          <label class="control-label" title="Vor. Lief.">Voraussichtlicher Liefertermin:</label>
          <date-range [start]="data['delivery_date_estimated_start']"
                      [end]="data['delivery_date_estimated_end']"
                      (update)="updateDeliveryDateEstimated($event)">
          </date-range>
        </div>
      </div>
    </div>
  </div>
</div>

<form #changeForm="ngForm" name="form">
  <table class="table whitetable">
    <thead>
    <tr>
      <th>
        <a (click)="sort($event, 'workflow_order_id', 'ASC')" [href]="sortUrl('workflow_order_id', 'ASC')">
          Bestellung Nr.
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'workflow_order_date', 'DESC')" [href]="sortUrl('workflow_order_date', 'DESC')">
          Datum
        </a>
      </th>
      <th>
        <a (click)="sort($event, 'workflow_id', 'ASC')"
           [href]="sortUrl('workflow_id', 'ASC')">
          Vorgang
        </a>
      </th>
      <th>
        Prüfung
      </th>

      <th>
        <a (click)="sort($event, 'article_sds_nr', 'ASC')" [href]="sortUrl('article_sds_nr', 'ASC')">
          SDS-Nr.
        </a>
      </th>

      <th>
        <a (click)="sort($event, 'article_oa_nr', 'ASC')" [href]="sortUrl('article_oa_nr', 'ASC')">
          0A-Nr.
        </a>
      </th>

      <th>
        <a (click)="sort($event, 'article_kd_art_nr', 'ASC')" [href]="sortUrl('article_kd_art_nr', 'ASC')">
          Kunden-Art-Nr.
        </a>
      </th>

      <th>
        <a (click)="sort($event, 'workflow_order_producer', 'ASC')" [href]="sortUrl('workflow_order_producer', 'ASC')">
          Produzent
        </a>
      </th>

      <th class="nowrap">
        <a (click)="sort($event, 'workflow_order_delivery_date', 'ASC')"
           [href]="sortUrl('workflow_order_delivery_date', 'ASC')">
          Lief.Ter.(ETD)
        </a>
      </th>

      <th class="nowrap">
        <a (click)="sort($event, 'workflow_order_date_estimated', 'ASC')"
           [href]="sortUrl('workflow_order_date_estimated', 'ASC')">
          Vor.Lief.
        </a>
      </th>

      <th class="nowrap">
        <a (click)="sort($event, 'workflow_fetch_date_delivery', 'ASC')"
           [href]="sortUrl('workflow_fetch_date_delivery', 'ASC')">
          Kd.Lief.
        </a>
      </th>

      <th>
        <a (click)="sort($event, 'workflow_order_request_nr', 'ASC')"
           [href]="sortUrl('workflow_order_request_nr', 'ASC')">
          Anfrage Nr.
        </a>
      </th>

      <th class="align-right">
        <a (click)="sort($event, 'workflow_order_quantity', 'ASC')"
           [href]="sortUrl('workflow_order_quantity', 'ASC')">
          Menge
        </a>
      </th>
      <th class="align-right">
        <a (click)="sort($event,'workflow_order_price', 'ASC')"
           [href]="sortUrl('workflow_order_price', 'ASC')">
          Preis
        </a>
      </th>

      <th>
        <a (click)="sort($event, 'customer_name', 'ASC')" [href]="sortUrl('customer_name', 'ASC')">
          Kunde
        </a>
      </th>

      <th>
        Buch. Nr
      </th>

      <th>
        RG Nr
      </th>

      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>

    <tr class="filterrow">
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sid" name="sid" [autofocus]
               autocomplete="off search-order-request-id">
      </th>
      <th>&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.workflow_id" name="workflow_id">
      </th>
      <th>
        <select class="form-control input-sm" [(ngModel)]="data.workflow_order_testing" name="testing">
          <option [ngValue]="null">-</option>
          <option value="E-Muster">E-Muster</option>
          <option value="Serie">Serie</option>
          <option value="Serie mit EMPB">Serie mit EMPB</option>
        </select>
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.sds_nr" name="sds_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.oa_nr" name="oa_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.kd_art_nr" name="kd_art_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.producer" name="producer">
      </th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.request_nr" name="request_nr">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.quanity" name="quanity">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.price" name="price">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.customer" name="customer">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.booking_id" name="booking_id">
      </th>
      <th>
        <input type="text" class="form-control input-sm" [(ngModel)]="data.supplier_rg_nr" name="supplier_rg_nr">
      </th>

      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
      <th>&nbsp;</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let obj of list.objects; let index = index" [ngClass]="{'alert-danger': obj.workflow_order_cfa}">
      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_id }}
          <workflow-state type="order_status" [key]="obj.workflow_order_status"></workflow-state>
          &nbsp;
          <i *ngIf="obj.workflow_order_mail_sent" class="fa fa-paper-plane ev-blue-color"
             title="Bestellung wurde per Mail versendet"></i>
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_id }}
          <workflow-state type="order_status" [key]="obj.workflow_order_status"></workflow-state>
          &nbsp;
          <i *ngIf="obj.workflow_order_mail_sent" class="fa fa-paper-plane ev-blue-color"
             title="Bestellung wurde per Mail versendet"></i>
        </div>
      </td>

      <td>{{ obj.workflow_order_date |date:'dd.MM.yyyy' }}</td>
      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_id }}
          <workflow-state type="workflow_status" [key]="obj.workflow_status"></workflow-state>
          <span class="fa fa-stack subscript right" ngbTooltip="Eildienst" style="display: inline-block" *ngIf="obj.workflow_fetch_express">
            <i class="fa fa-stack-1x fa-exclamation-triangle"></i>
            <i class="fa fa-stack-2x fa-truck" style="font-size: 12px;"></i>
          </span>
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_id }}
          <workflow-state type="workflow_status" [key]="obj.workflow_status"></workflow-state>
        </div>
      </td>
      <td>
        <span class="label label-{{ obj.label }}">{{ obj.workflow_order_testing }}</span>
      </td>
      <td>
        {{ obj.article_sds_nr }}
        <span class="label label-danger" *ngIf="obj.article_data.special_production">Spezial</span>
      </td>
      <td>
        <div class="label"
             [ngClass]="{'label-danger': obj.article_hash !== obj.workflow_order_article_hash,
                     'label-success': obj.article_hash === obj.workflow_order_article_hash }">
          {{ obj.article_oa_nr }}
        </div>
      </td>
      <td>{{ obj.article_data.kd_art_nr }} {{ obj.article_data.kd_art_index}}</td>
      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_producer }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_producer }}
        </div>
      </td>
      <td class="nowrap">
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_delivery_date|date:'dd.MM.yyyy' }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_delivery_date|date:'dd.MM.yyyy' }}
        </div>

        <cn-order-delivery-method [spaced]="true" [size17]="true"
                                  [deliveryMethod]="data.confirmed?.delivery_method"
                                  *ngFor="let data of obj.workflow_order_data">
        </cn-order-delivery-method>
      </td>

      <td>
        <div class="content">
          <ng-container *ngFor="let date of obj.workflow_order_delivery_dates_estimated; let i = index">
            {{ date|date:'dd.MM.yyyy' }}
          </ng-container>
        </div>
      </td>

      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_fetch_date_delivery|date:'dd.MM.yyyy' }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_fetch_date_delivery|date:'dd.MM.yyyy' }}
        </div>
      </td>

      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_request_nr }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_request_nr }}
        </div>
      </td>
      <td class="align-right">
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_quantity |money}}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_quantity |money}}
        </div>
      </td>
      <td class="align-right">
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.workflow_order_price |money:3}}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.workflow_order_price |money:3}}
        </div>
      </td>
      <td>
        <a uiSref="a.crm.customer.detail" [uiParams]="{id:obj.customer_id}">
          {{ obj.customer_name }}
        </a>
      </td>
      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.booking_ids|Concat }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.booking_ids|Concat }}
        </div>
      </td>
      <td>
        <a *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)"
           uiSref="a.workflow.request.detail" [uiParams]="{id: obj.workflow_id}">
          {{ obj.supplier_rg_nrs|Concat }}
        </a>

        <div class="content" *ngIf="!(('task.view'|HasPerm)||('task.offer.view'|HasPerm))">
          {{ obj.supplier_rg_nrs|Concat }}
        </div>
      </td>
      <td class="icon">
        <ev-file-button [objId]="obj.workflow_order_id" type="order"
                        *ngIf="('task.view'|HasPerm)||('task.offer.view'|HasPerm)">
          <i class="fa fa-file-pdf-o"></i>
        </ev-file-button>
      </td>
      <td class="icon">
        <button class="btn btn-link btn-sm" type="button"
                *ngIf="(('task.view'|HasPerm)||('task.offer.view'|HasPerm))&& obj.workflow_order_status < 50"
                (click)="mail(obj.workflow_order_id, obj.workflow_id, obj.workflow_order_producer)">
          <i class="fa fa-envelope"></i>
        </button>
      </td>
      <td class="icon">
        <button type="button" class="btn btn-link btn-sm" style="color: red;" (click)="cancel(obj)"
                *ngIf="obj.workflow_order_status" title="Bestellung stornieren">
          <i class="fa fa-ban"></i>
        </button>
      </td>
      <td class="icon">
        <button type="button" class="btn btn-link btn-sm" style="color: red;" (click)="remove(obj)"
                *ngIf="obj.workflow_order_status < 50" title="Bestellung löschen">
          <i class="fa fa-trash-o"></i>
        </button>
      </td>
      <td>
        <button type="button" class="btn btn-default btn-sm" (click)="open(obj)"
                *ngIf="obj.workflow_order_status < 50">
          Übersicht
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</form>

<ev-navigation [page]="list.page" [pages]="list.pages" [query]="query()" (goto)="goto($event)"></ev-navigation>
