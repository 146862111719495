import {NgModule} from '@angular/core';
import {UIRouterModule} from '@uirouter/angular';
import {NgbModalModule, NgbNavModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedModule} from '../../core/shared.module';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {ARTICLE_MANAGEMENT_STATES} from './material-management.states';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialArticleListComponent} from './articles/material-article-list.component';
import {MaterialFieldCreateComponent} from './fields/material-field-create.component';
import {MaterialVariationRowComponent} from './variations/material-variation-row.component';
import {MaterialInventorySelectComponent} from './inventory/material-inventory-select.component';
import {MaterialInventoryDetailComponent} from './inventory/material-inventory-detail.component';
import {MaterialGroupCreateComponent} from './groups/material-group-create.component';
import {MaterialSelectArticleComponent} from './articles/material-article-select.component';
import {MaterialGroupListComponent} from './groups/material-group-list.component';
import {MaterialFieldListComponent} from './fields/material-field-list.component';
import {MaterialVariationTableComponent} from './variations/material-variation-table.component';
import {MaterialInventoryListComponent} from './inventory/material-inventory-list.component';
import {MaterialNavigationComponent} from './material-navigation.component';
import {MaterialFieldSelectGroupComponent} from './fields/material-field-select-group.component';
import {MaterialArticleCreateComponent} from './articles/material-article-create.component';
import {MaterialArticleCreateFormComponent} from './articles/material-article-create-form.component';
import {LoadingOrErrorModule} from '../../common/loading/loading-or-error.module';
import {MaterialArticleCreateFormHolderComponent} from './articles/material-article-create-form-holder.component';
import {MaterialArticleUpdateFormHolderComponent} from './articles/material-article-update-form-holder.component';
import {MaterialArticleErrorMessageComponent} from './articles/material-article-error-message.component';
import {MaterialUnitsListComponent} from './units/material-units-list.component';
import {MaterialUnitsCreateComponent} from './units/material-units-create.component';
import {MaterialSupplierSelectorComponent} from './supplier-selector/material-supplier-selector.component';
import {MaterialSupplierSelectComponent} from './supplier-selector/material-supplier-select.component';
import {MaterialInventoryListGroupSelectComponent} from './inventory/material-inventory-list-group-select.component';
import { MaterialInventoryFieldSwitchComponent } from './inventory/material-inventory-field-switch/material-inventory-field-switch.component';
import { MaterialInventoryKonsiSwitchComponent } from './inventory/material-inventory-konsi-switch/material-inventory-konsi-switch.component';
import { MaterialInventoryTransferStockModalComponent } from './inventory/material-inventory-transfer-stock-modal/material-inventory-transfer-stock-modal.component';
import { MaterialInventoryChangeModalComponent } from './inventory/material-inventory-change-modal/material-inventory-change-modal.component';
import { MaterialInventoryStorageRowComponent } from './inventory/material-inventory-storage-row/material-inventory-storage-row.component';
import { MaterialInventoryIntakeModalComponent } from './inventory/material-inventory-intake-modal/material-inventory-intake-modal.component';
import { MaterialInventoryNavigationComponent } from './inventory/material-inventory-navigation/material-inventory-navigation.component';
import { MaterialInventoryTransactionErrorComponent } from './inventory/material-inventory-transaction-error/material-inventory-transaction-error.component';
import { MaterialInventoryTransferKonsiModalComponent } from './inventory/material-inventory-transfer-konsi-modal/material-inventory-transfer-konsi-modal.component';
import { MaterialInventoryEditModalComponent } from './inventory/material-inventory-edit-modal/material-inventory-edit-modal.component';
import { MaterialOrderListComponent } from './order/list/material-order-list.component';
import { MaterialOrderNavigationComponent } from './order/navigation/material-order-navigation.component';
import { MaterialOrderCreateComponent } from './order/create/material-order-create.component';
import { MaterialOrderCreateListComponent } from './order/create/list/material-order-create-list.component';
import { MaterialOrderCreateRowComponent } from './order/create/material-order-create-row.component';
import { MaterialOrderCreateListRowComponent } from './order/create/list/material-order-create-list-row.component';
import { MaterialOrderCreateGroupComponent } from './order/create/material-order-create-group.component';
import { MaterialOrderUpdateComponent } from './order/update/material-order-update.component';
import { MaterialOrderCreateGroupSelectComponent } from './order/create/material-order-create-group-select.component';
import { MaterialOrderStatusComponent } from './order/order-status/material-order-status.component';
import { MaterialOrderPositionStatusSelectComponent } from './order/position-status/material-order-position-status-select.component';
import { MaterialOrderPositionStatusComponent } from './order/position-status/material-order-position-status.component';
import { MaterialOrderIntakeComponent } from './order/intake/material-order-intake.component';
import { MaterialOrderIntakeRowComponent } from './order/intake/material-order-intake-row.component';
import { MaterialOrderIntakeGroupComponent } from './order/intake/material-order-intake-group.component';
import { MaterialOrderIntakeFormComponent } from './order/intake/material-order-intake-form.component';
import { MaterialOrderIntakePositionComponent } from './order/intake/material-order-intake-position.component';
import { MaterialOrderUpdateGroupComponent } from './order/update/material-order-update-group.component';
import { MaterialOrderUpdatePositionComponent } from './order/update/material-order-update-position.component';
import { MaterialOrderIntakeTransactionRowComponent } from './order/intake/material-order-intake-transaction-row.component';
import {Commentv2Module} from '../commentv2/commentv2.module';
import { MaterialArticleOrderListComponent } from './order/article-order-list/material-article-order-list.component';
import {ArticleFilters} from './order/create/filters-pipe/article-filters.pipes';
import {MaterialOrderIntakeBookedGroupComponent} from './order/intake/booked-list/material-order-intake-booked-group.component';
import {MaterialOrderIntakeBookedPositionComponent} from './order/intake/booked-list/material-order-intake-booked-position.component';
import {MaterialOrderIntakeBookedRowComponent} from './order/intake/booked-list/material-order-intake-booked-row.component';
import { MaterialVariationEditModalComponent } from './inventory/material-variation-edit-modal/material-variation-edit-modal.component';
import {MaterialInventoryTransactionListComponent} from './inventory/material-inventory-transaction-list/material-inventory-transaction-list.component';
import {MaterialFieldValueComponent} from './fields/material-field-value.component';
import {MaterialPlanningListComponent} from './planning/material-planning-list.component';
import {MaterialPlanningRowComponent} from './planning/material-planning-row.component';
import {CustomerBoxModule} from '../customer/box/customer-box.module';
import {MaterialPlanningForceCheckButtonComponent} from './planning/material-planning-force-check-button.component';
import {MaterialPlanningSidebarComponent} from './planning/material-planning-sidebar.component';
import {MaterialOrderPositionSmallListComponent} from './order/position-small-list/material-order-position-small-list.component';
import {MaterialPlanningSmallListComponent} from './planning/material-planning-small-list.component';
import {MaterialOrderPositionListComponent} from './order/position-list/material-order-position-list.component';
import {MaterialOrderPositionListRowComponent} from './order/position-list/material-order-position-list-row.component';
import {MaterialOrderIntakePrintModalComponent} from './order/intake/intake-print-modal/material-order-intake-print-modal.component';
import {MaterialOrderIntakePrintRowComponent} from './order/intake/intake-print-modal/material-order-intake-print-row.component';
import {MaterialPlanningDateCheckComponent} from './planning-date-check/material-planning-date-check.component';
import { MaterialPlanningDateCheckModalComponent } from './planning-date-check/material-planning-date-check-modal.component';
import { MaterialPlanningDateCheckModalRowComponent } from './planning-date-check/material-planning-date-check-modal-row.component';
import { MaterialOrderIntakeBookedFormComponent } from './order/intake/booked-list/material-order-intake-booked-form.component';
import { MaterialOrderIntakeBookedRowEmptyComponent } from './order/intake/booked-list/material-order-intake-booked-row-empty.component';
import { MaterialUserListComponent } from './user-list/material-user-list.component';
import { MaterialLabelPrintButtonComponent } from './labels/material-label-print-button.component';
import {MaterialNavigationModule} from './navigation/material-navigation.module';
import {MaterialProductGroupsModule} from './product-groups/material-product-groups.module';

@NgModule({
    imports: [
        UIRouterModule.forChild({ states: ARTICLE_MANAGEMENT_STATES }),
        NgbTooltipModule,
        SharedModule,
        InfiniteScrollModule,
        ReactiveFormsModule,
        NgbModalModule,
        LoadingOrErrorModule,
        NgbNavModule,
        Commentv2Module,
        CustomerBoxModule,
        MaterialNavigationModule,
        MaterialProductGroupsModule,
        MaterialNavigationModule,
    ],
    declarations: [
        MaterialArticleListComponent,
        MaterialGroupListComponent,
        MaterialFieldListComponent,
        MaterialFieldCreateComponent,
        MaterialVariationTableComponent,
        MaterialNavigationComponent,
        MaterialVariationRowComponent,
        MaterialFieldSelectGroupComponent,
        MaterialInventoryListComponent,
        MaterialInventorySelectComponent,
        MaterialInventoryDetailComponent,
        MaterialSelectArticleComponent,
        MaterialArticleCreateComponent,
        MaterialArticleCreateFormComponent,
        MaterialArticleCreateFormHolderComponent,
        MaterialArticleUpdateFormHolderComponent,
        MaterialArticleErrorMessageComponent,
        MaterialUnitsListComponent,
        MaterialSupplierSelectorComponent,
        MaterialInventoryListGroupSelectComponent,
        MaterialInventoryFieldSwitchComponent,
        MaterialInventoryKonsiSwitchComponent,
        MaterialInventoryStorageRowComponent,
        // Modals
        MaterialUnitsCreateComponent,
        MaterialGroupCreateComponent,
        MaterialSupplierSelectComponent,
        MaterialInventoryTransferStockModalComponent,
        MaterialInventoryChangeModalComponent,
        MaterialInventoryIntakeModalComponent,
        MaterialInventoryNavigationComponent,
        MaterialInventoryTransactionErrorComponent,
        MaterialInventoryTransferKonsiModalComponent,
        MaterialInventoryEditModalComponent,
        MaterialOrderListComponent,
        MaterialOrderNavigationComponent,
        MaterialOrderCreateComponent,
        MaterialOrderCreateListComponent,
        MaterialOrderCreateRowComponent,
        MaterialOrderCreateListRowComponent,
        MaterialOrderCreateGroupComponent,
        MaterialOrderUpdateComponent,
        MaterialOrderCreateGroupSelectComponent,
        MaterialOrderStatusComponent,
        MaterialOrderPositionStatusSelectComponent,
        MaterialOrderPositionStatusComponent,
        MaterialOrderIntakeComponent,
        MaterialOrderIntakeRowComponent,
        MaterialOrderIntakeGroupComponent,
        MaterialOrderIntakeFormComponent,
        MaterialOrderIntakePositionComponent,
        MaterialOrderIntakeBookedGroupComponent,
        MaterialOrderIntakeBookedPositionComponent,
        MaterialOrderIntakeBookedRowComponent,
        MaterialOrderUpdateGroupComponent,
        MaterialOrderUpdatePositionComponent,
        MaterialOrderIntakeTransactionRowComponent,
        MaterialArticleOrderListComponent,
        ArticleFilters,
        MaterialVariationEditModalComponent,
        MaterialInventoryTransactionListComponent,
        MaterialFieldValueComponent,
        MaterialPlanningListComponent,
        MaterialPlanningRowComponent,
        MaterialPlanningForceCheckButtonComponent,
        MaterialPlanningSidebarComponent,
        MaterialOrderPositionSmallListComponent,
        MaterialPlanningSmallListComponent,
        MaterialOrderPositionListComponent,
        MaterialOrderPositionListRowComponent,
        MaterialOrderIntakePrintModalComponent,
        MaterialOrderIntakePrintRowComponent,
        MaterialPlanningDateCheckComponent,
        MaterialPlanningDateCheckModalComponent,
        MaterialPlanningDateCheckModalRowComponent,
        MaterialOrderIntakeBookedFormComponent,
        MaterialOrderIntakeBookedRowEmptyComponent,
        MaterialUserListComponent,
        MaterialLabelPrintButtonComponent,
    ],
    exports: [
        MaterialFieldValueComponent,
        MaterialPlanningRowComponent,
        MaterialPlanningForceCheckButtonComponent,
        MaterialOrderPositionSmallListComponent,
        MaterialOrderPositionListRowComponent,
        MaterialPlanningDateCheckComponent,
        MaterialPlanningDateCheckModalRowComponent,
    ]
})
export class MaterialManagementModule { }
